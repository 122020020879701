import {
  useTheme,
  useMediaQuery,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  Button,
  Hidden,
  SwipeableDrawer,
  Drawer,
  Tab,
  Tabs,
  ThemeProvider,
  Chip,
  Fade,
} from "@material-ui/core";
import { Clear, Share } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import useCleverlynkFunctions from "../../hooks/useCleverlynkFunctions";
import { hexaWithAToRGB, useClynkTheme } from "../../hooks/useClynkTheme";
import { useTranslation } from "react-i18next";
import "../../i18n/config";
import useTemplateStyles from "../../hooks/useTemplateStyles";
import { PFXSubItemChosenOption } from "../../models/Interfaces";
import {
  selectItemsInCart,
  selectCompany,
  selectCleverlynkStage,
  selectUnavailable,
  selectCartIsOpen,
} from "../../redux/slices/cleverlynkSlice";
import { selectRecentlyViewed } from "../../redux/slices/templateSlice";
import SendCheckoutModal from "../Checkout/SendCheckoutModal";
import CartContent from "./CartContent";
import RecentItems from "./RecentItems";
import { ReactComponent as CartTop } from "../../images/cart-top2.svg";
import { ReactComponent as CartBottom } from "../../images/cart-bottom.svg";
import useDisableOverflow from "../../hooks/useDisableOverflow";

enum ShoppingCartStage {
  "CART",
  "RECENT",
}
export interface IShoppingCart2 {
  isMobile: boolean;
}

const ShoppingCart2 = (props: IShoppingCart2) => {
  const [stage, setStage] = useState<number>(ShoppingCartStage.CART);
  const [openSendCheckoutModal, setOpenSendCheckoutModal] = useState<boolean>(false);

  const recentlyViewed = useSelector(selectRecentlyViewed);
  const itemsInCart = useSelector(selectItemsInCart);
  const company = useSelector(selectCompany);
  const cartIsOpen = useSelector(selectCartIsOpen);
  const unavailable = useSelector(selectUnavailable);
  const cleverlynkStage = useSelector(selectCleverlynkStage);

  const { t, i18n } = useTranslation(["header", "common"]);

  const animateChipRef = useRef<boolean>(false);
  const cleverlynkStageRef = useRef<number>(1);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { titleStyles, cardStyles } = useTemplateStyles();

  const { createOrderSummaryTheme } = useClynkTheme();
  const { handleModifyCartIsOpen, gotToUserForm, handleModifyTriggerNavToUserForm } = useCleverlynkFunctions();
  const { disable, enable } = useDisableOverflow();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paperCard: {
        padding: theme.spacing(2),
      },
      title: {
        marginTop: theme.spacing(2),
      },
      tabsRoot: {
        justifyContent: isMobile ? "flex-start" : "space-evenly",
      },
      tabRoot: {
        minWidth: "90px !important",
        paddingRight: 10,
        [theme.breakpoints.down("md")]: {
          fontSize: "0.8rem",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "1rem",
        },
      },
      drawerRoot: {
        //@ts-ignore
        zIndex: "1304 !important",
      },
      drawerPaper: {
        [theme.breakpoints.down("md")]: {
          width: "90%",
        },
        [theme.breakpoints.up("md")]: {
          width: 375,
        },
        overflowX: "hidden",
        maxWidth: 375,
        zIndex: 1303,
      },
      paperWrap: {
        backgroundColor: "#f9f9f9",
        paddingTop: isMobile ? 10 : 15,
        position: "relative",
        padding: 15,
        height: "100%",
        zIndex: -2,
        overflowX: "hidden",
      },
      tabs: {},
      "@keyframes flash": {
        "0%": {
          backgroundColor: "#808080",
          color: "white",
        },
        "50%": {
          backgroundColor: "#51d67d",
          color: "white",
        },
        "100%": {
          backgroundColor: "#808080",
          color: "white",
        },
      },
      chipRoot: {
        height: 20,
        width: 20,
        marginTop: -5,
        backgroundColor: "#808080",
        color: "white",
      },
      animateChip: {
        animation: "$flash 1s ease 2",
      },
      topSvg: {
        top: 0,
        right: -1,
        position: "absolute",
        maxHeight: "40%",
        zIndex: -1,
        [theme.breakpoints.up("md")]: {
          width: 378,
        },
        maxWidth: 378,
      },
      chipLabel: {
        padding: 0,
        fontFamily: "Jost",
      },
      fade: {
        animation: "$fade 1s ease-in",
      },
      "@keyframes fade": {
        "0%": {
          opacity: 0,
        },
        "100%": {
          opacity: 1,
        },
      },
      payButton: {
        zIndex: 3000,
        position: "fixed",
        display: unavailable?.closed ? "none" : "",
        bottom: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
          width: "80%",
        },
        [theme.breakpoints.up("md")]: {
          width: "30%",
        },
      },
      nextButton: {
        position: "fixed",
        bottom: 40,
        right: 30,
        [theme.breakpoints.down("md")]: {
          width: "75%",
        },
        [theme.breakpoints.up("md")]: {
          width: 300,
        },
      },
      stickyBar: {
        backgroundColor: hexaWithAToRGB(cardStyles?.buttonColor),
        position: "fixed",
        marginLeft: -15,
        marginTop: -15,
        padding: 10,
        paddingRight: 0,
        zIndex: 100,
        [theme.breakpoints.down("md")]: {
          width: "90%",
        },
        [theme.breakpoints.up("md")]: {
          width: 375,
        },
      },
      topMenuGrid: {
        width: isMobile ? "92%" : "100%",
      },
    })
  );
  const classes = useStyles();

  const itemsInCartAmount = useMemo(() => itemsInCart.reduce((p, n) => (n?.quantity ?? 0) + p, 0), [itemsInCart]);

  useEffect(() => {
    animateChipRef.current = true;
    const t = setTimeout(() => {
      animateChipRef.current = false;
    }, 1000);
    return () => clearTimeout(t);
  }, [itemsInCartAmount]);

  useEffect(() => {
    if (cartIsOpen && isMobile) {
      disable();
    } else if (!cartIsOpen && isMobile) {
      enable();
    }
  }, [cartIsOpen, isMobile]);

  useEffect(() => {
    if (cleverlynkStage !== cleverlynkStageRef.current) {
      cleverlynkStageRef.current = cleverlynkStage;
      handleModifyCartIsOpen(false);
    }
  }, [cleverlynkStage]);

  function onClose(event: React.MouseEvent | React.KeyboardEvent) {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    handleModifyCartIsOpen(false);
  }

  const content = useMemo(() => {
    switch (stage) {
      case ShoppingCartStage.CART:
        return <CartContent />;
      case ShoppingCartStage.RECENT:
        return <RecentItems />;
      default:
        break;
    }
  }, [stage, itemsInCart]);

  const tabs = useMemo(
    () => (
      <Tabs
        key={"order-summary-tabs"}
        value={stage}
        onChange={(e, v) => setStage(v)}
        classes={{
          flexContainer: classes.tabsRoot,
        }}>
        <Tab
          classes={{ root: classes.tabRoot }}
          value={ShoppingCartStage.CART}
          label={
            <Grid container>
              <Grid item>
                <Typography>{t("header:shoppingCart.myCart")}</Typography>
              </Grid>
              <Grid item style={{ marginLeft: 3 }}>
                <Fade in={!!itemsInCart.length && stage === ShoppingCartStage.RECENT}>
                  <Chip
                    classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                    className={animateChipRef.current ? classes.animateChip : ""}
                    color="primary"
                    label={itemsInCartAmount}
                  />
                </Fade>
              </Grid>
            </Grid>
          }
        />
        {!!recentlyViewed.length && (
          <Tab
            classes={{ root: classes.tabRoot }}
            value={ShoppingCartStage.RECENT}
            label={
              <Grid container>
                <Grid item>
                  <Typography>{t("header:shoppingCart.recentlyViewed")}</Typography>
                </Grid>
                <Grid item style={{ marginLeft: 3 }}>
                  <Fade in={!!recentlyViewed.length && stage === ShoppingCartStage.CART}>
                    <Chip
                      classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                      color="secondary"
                      label={recentlyViewed.length}
                    />
                  </Fade>
                </Grid>
              </Grid>
            }
          />
        )}
      </Tabs>
    ),
    [classes.chipLabel, classes.chipRoot, classes.tabRoot, classes.tabsRoot, recentlyViewed.length, stage]
  );

  const nextButton = useMemo(
    () =>
      cleverlynkStage === 1 && stage === ShoppingCartStage.CART && itemsInCart.length > 0 ? (
        <Button
          size="large"
          variant="contained"
          color="primary"
          className={classes.nextButton}
          onClick={() => {
            handleModifyTriggerNavToUserForm(true);
            gotToUserForm();
            // props.setOpen(false);
          }}>
          <Typography variant="subtitle1" id="next">
            <strong>{t("common:next")}</strong>
          </Typography>
        </Button>
      ) : null,
    [classes.nextButton, cleverlynkStage, gotToUserForm, recentlyViewed.length, stage]
  );

  const goToCartButton = useMemo(
    () =>
      stage === ShoppingCartStage.RECENT && itemsInCart.length > 0 ? (
        <Button
          size="large"
          variant="contained"
          color="primary"
          className={classes.nextButton}
          onClick={() => {
            setStage(ShoppingCartStage.CART);
          }}>
          <Typography variant="subtitle1">{t("header:shoppingCart.goToCart")}</Typography>
        </Button>
      ) : null,
    [classes.nextButton, cleverlynkStage, gotToUserForm, recentlyViewed.length, stage]
  );

  return (
    <ThemeProvider
      theme={createOrderSummaryTheme(
        titleStyles?.textFontFamily,
        cardStyles?.buttonColor,
        hexaWithAToRGB(cardStyles?.buttonColor)
      )}>
      <Hidden mdUp>
        <SwipeableDrawer
          anchor="right"
          open={cartIsOpen}
          onClose={onClose}
          onOpen={() => handleModifyCartIsOpen(true)}
          classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}>
          <div className={classes.paperWrap}>
            <div id="fade-in">
              <React.Fragment>
                <CartTop fill={`${hexaWithAToRGB(cardStyles?.buttonColor)}`} className={classes.topSvg} />
              </React.Fragment>
            </div>
            <div className={classes.stickyBar}>
              <Grid container justifyContent="space-between" className={classes.topMenuGrid}>
                <Button startIcon={<Clear />} onClick={() => handleModifyCartIsOpen(false)}>
                  <Typography variant="caption">{t("header:shoppingCart.close")}</Typography>
                </Button>
                <Button endIcon={<Share />} onClick={() => setOpenSendCheckoutModal(true)}>
                  <Typography variant="caption">{t("header:shoppingCart.share")}</Typography>
                </Button>
              </Grid>
              {tabs}
            </div>
            {content}
            {nextButton}
            {goToCartButton}
          </div>
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="right"
          variant="persistent"
          open={cartIsOpen}
          classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}>
          <div className={classes.paperWrap}>
            <div id="fade-in">
              <React.Fragment>
                <CartTop fill={`${hexaWithAToRGB(cardStyles?.buttonColor)}`} className={classes.topSvg} />
              </React.Fragment>
            </div>
            <div className={classes.stickyBar}>
              <Grid container justifyContent="space-between" style={{ width: "95%" }}>
                <Button startIcon={<Clear />} onClick={() => handleModifyCartIsOpen(false)}>
                  <Typography variant="caption">{t("header:shoppingCart.close")}</Typography>
                </Button>
                <Button endIcon={<Share />} onClick={() => setOpenSendCheckoutModal(true)} id="share-cart">
                  <Typography variant="caption">{t("header:shoppingCart.share")}</Typography>
                </Button>
              </Grid>
              {tabs}
            </div>
            {tabs}
            {content}
            {nextButton}
            {goToCartButton}
          </div>
        </Drawer>
      </Hidden>
      <SendCheckoutModal
        open={openSendCheckoutModal}
        onClose={() => setOpenSendCheckoutModal(false)}
        companyName={company?.name ?? ""}
      />
    </ThemeProvider>
  );
};

export function getOptionsPrice(options: PFXSubItemChosenOption[]) {
  let price = 0;
  options.forEach(o => {
    price += o.value! * o.quantity!;
  });
  return price;
}

export default ShoppingCart2;
