import { makeStyles, createStyles, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Theme } from "react-select";
import { selectIsMobile } from "../../redux/slices/cleverlynkSlice";
import { ISummaryBannerInfo } from "./StatusBanner";
import StatusLoader from "./StatusLoaders";

interface IStatusBannerHeader {
  title: string;
  subtitle?: string;
  info: ISummaryBannerInfo;
}

const StatusBannerHeader = ({ title, subtitle, info }: IStatusBannerHeader) => {
  const isMobile = useSelector(selectIsMobile);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      header: {
        maxWidth: 1000,
        top: 100,
        transition: "height 500ms",
        width: isMobile ? "94%" : "70%",
        minHeight: "70%",
        backgroundColor: "#fff",
        borderRadius: "12px",
        position: "absolute",
        boxShadow: "3pt 3pt 4px 0px #0000001f",
        paddingTop: 15,
      },
      title: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: isMobile ? "2.5rem" : "3.2rem",
        fontWeight: 900,
        fontFamily: "system-ui",
        textAlign: "center",
      },
    })
  );
  const classes = useStyles();

  return (
    <div className={classes.header} id="order-summary-header">
      <Grid
        item
        xs={12}
        style={{
          paddingTop: 0,
          display: "flex",
          justifyContent: "center",
          top: -30,
          position: "absolute",
          color: "white",
          width: "100%",
        }}>
        <Typography
          variant="caption"
          style={{ textAlign: "center", color: "#fff", fontSize: isMobile ? "0.85rem" : "1rem" }}>
          {info.subtitle}
        </Typography>
      </Grid>
      <Grid container alignContent="flex-start" justifyContent="center" style={{ height: "100%" }}>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.title}>
            {info.title}
          </Typography>
        </Grid>
        {subtitle && (
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.title}>
              {info.subtitle}
            </Typography>
          </Grid>
        )}
        <StatusLoader info={info} />
      </Grid>
    </div>
  );
};
export default StatusBannerHeader;
