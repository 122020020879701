import { Grid, ThemeProvider, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import StatusBanner from "../components/OrderSummary/StatusBanner";
import logo from "../images/logomini.png";
import SummaryTabs from "../components/OrderSummary/SummaryTabs";
import useCleverlynkFunctions from "../hooks/useCleverlynkFunctions";
import { useClynkTheme } from "../hooks/useClynkTheme";
import { useSelector } from "react-redux";
import {
  selectCleverlynk,
  selectIsMobile,
  selectLoadOrderSummary,
  selectOrderSummary,
} from "../redux/slices/cleverlynkSlice";
import LoadingSkeleton from "../components/OrderSummary/LoadingSkeleton";
import { SubscriptionValue } from "../models/graphql-api";
import { mapOnNewOrderSubscription, subscribeOrderQuery } from "../models/Subscriptions/OrderSummary";
import { API, graphqlOperation } from "aws-amplify";
import "../css/OrderSummary.css";
import SummaryDesktop from "../components/OrderSummary/SummaryDesktop";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../i18n/config";
import { PFXOrderFulfillmentType } from "../models/Interfaces";

export enum IOrderState {
  TRANSFER = "transfer",
  SHIPPING = "shipping",
  PENDING = "pending",
  PREPARATION = "preparation",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  CANCELLED = "cancelled",
  DELIVERED = "delivered",
  PICKUP = "pickup",
  NONEXISTANT = "non-existant",
}

export const getOrderStatus = (orderStatus, status, fulfillmentType): IOrderState => {
  // TODO pasar a switch
  if (status === 4) return IOrderState.DELIVERED;
  else if (status === -1) return IOrderState.CANCELLED;
  else if (status === 3) {
    if (fulfillmentType === PFXOrderFulfillmentType.DELIVERY) {
      return IOrderState.SHIPPING;
    } else return IOrderState.PICKUP;
  } else if (status === 2) return IOrderState.PREPARATION;
  else if (orderStatus === 200) return IOrderState.TRANSFER;
  else if (status === 0) return IOrderState.PENDING;
  else if (status === 1) return IOrderState.ACCEPTED;
  else if (status === -2) return IOrderState.REJECTED;
  else return IOrderState.PENDING;
};

const OrderSummary = props => {
  const {
    handleLoadOrderSummary,
    handleModifyOrderState,
    handleModifySnackbar,
    handleModifyOrderSummary,
    handleWipeState,
  } = useCleverlynkFunctions();
  const { createOrderSummaryTheme } = useClynkTheme();

  const cleverlynk = useSelector(selectCleverlynk);
  const isMobile = useSelector(selectIsMobile);
  const orderSummary = useSelector(selectOrderSummary);
  const orderSummarySupervisor = useSelector(selectLoadOrderSummary);

  const [nonExistant, setNonExistant] = useState<boolean>(false);

  const { i18n } = useTranslation();

  const scrollUp = () => {
    const elem = document.getElementsByTagName("html");
    setTimeout(() => {
      if (elem && elem[0]) {
        elem[0].scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }, 500);
  };

  useEffect(() => {
    const t = setTimeout(() => {
      document.body.style.backgroundColor = "#f2f2f2";
      document.title = "Tu Orden";
    }, 3000);
    return () => clearTimeout(t);
  }, []);

  useEffect(() => {
    if (cleverlynk?.language) {
      i18n.changeLanguage(cleverlynk?.language);
    }
  }, [cleverlynk]);

  useEffect(() => {
    handleLoadOrderSummary(props.match.params.orderId);
    handleModifySnackbar(null);
    handleWipeState();
    window.addEventListener("popstate", function (event) {
      const elem = document.getElementById("back-to-catalog");
      if (elem) {
        elem.classList.add("strobe");
        scrollUp();
      }
      window.history.pushState(null, document.title, window.location.href);
    });

    window.history.pushState(null, document.title, window.location.href);
  }, [props.match.params.orderId]);

  const disableAllTimeouts = () => {
    var highestTimeoutId = setTimeout(";");
    for (var i = 0; i < highestTimeoutId; i++) {
      clearTimeout(i);
    }
  };

  useEffect(() => {
    if (orderSummarySupervisor.status === "succeeded") {
      const subscription = (
        API.graphql(graphqlOperation(subscribeOrderQuery(orderSummary?.id ?? ""))) as any
      ).subscribe({
        next: (response: SubscriptionValue<any>) => {
          const o = mapOnNewOrderSubscription(response);
          handleModifyOrderSummary(o);
          handleModifySnackbar(null);
          disableAllTimeouts();
        },
      });
      if (nonExistant) {
        setNonExistant(false);
      }
      return () => subscription.unsubscribe();
    } else if (orderSummarySupervisor.status === "failed") {
      setNonExistant(true);
    }
  }, [orderSummarySupervisor]);

  useEffect(() => {
    // console.log({ orderSummary });
    handleModifyOrderState(
      getOrderStatus(orderSummary?.paymentStatus, orderSummary?.status, orderSummary?.fulfillmentType)
    );
  }, [orderSummary]);

  const content = React.useMemo(() => {
    if (isMobile) {
      switch (orderSummarySupervisor.status) {
        case "loading":
          return <LoadingSkeleton />;
        case "succeeded":
          return <SummaryTabs />;
        default:
          return <SummaryTabs />;
      }
    } else {
      switch (orderSummarySupervisor.status) {
        case "loading":
          return <LoadingSkeleton desktop />;
        case "succeeded":
          return <SummaryDesktop />;
        default:
          return <SummaryDesktop />;
      }
    }
  }, [isMobile, orderSummarySupervisor, orderSummary, cleverlynk]);

  return (
    <div id="order-summary">
      <ThemeProvider theme={createOrderSummaryTheme()}>
        <StatusBanner title="Titulo" nonExistant={nonExistant} />
        {!nonExistant && content}
        <Grid item container justifyContent="center" xs={12} style={{ marginBottom: 24 }}>
          <Typography>Powered by</Typography>
          <img
            src={logo}
            alt={"Powered by Cleverlynk"}
            height={23}
            style={{ marginLeft: 15, cursor: "pointer" }}
            onClick={() => (window.location.href = "https://cleverlynk.com")}
          />
        </Grid>
      </ThemeProvider>
    </div>
  );
};
export default OrderSummary;
