import {
  Button,
  Collapse,
  createStyles,
  Fade,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ArrowLeft } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useCleverlynkFunctions from "../../hooks/useCleverlynkFunctions";
import { IOrderState } from "../../pages/OrderSummary";
import {
  selectIsMobile,
  selectLoadOrderSummary,
  selectOrderState,
  selectOrderSummary,
} from "../../redux/slices/cleverlynkSlice";
import StatusBannerHeader from "./StatusBannerHeader";

import { useTranslation } from "react-i18next";
import "../../i18n/config";
import { PFXOrderFulfillmentType } from "../../models/Interfaces";

export const SummaryColorCode = {
  TRANSFER_1: "#2E294E",
  TRANSFER_2: "#332687",
  PENDING_1: "#9240F2",
  PENDING_2: "#6200FF",
  ACCEPTED_1: "#00AD6B",
  ACCEPTED_2: "#32D68F",
  PREPARATION_1: "#DD8F3A",
  PREPARATION_2: "#EACE28",
  CUSTOM_STEP_1: "#FF5858",
  CUSTOM_STEP_2: "#F4A6A6",
  SHIPPING_1: "#11B0BC",
  SHIPPING_2: "#1AE8E3",
  DELIVERED_1: "#FF0F7B",
  DELIVERED_2: "#F89B29",
  CANCELLED_1: "#F44E86",
  CANCELLED_2: "#ED0A56",
  REJECTED_1: "#2a2a2a",
  REJECTED_2: "#424242",
};

export interface ISummaryBannerInfo {
  loaders: ISummaryLoader[];
  title: string;
  message: string;
  subtitle: string;
  nextColor: string;
  actualColor: string;
}
export interface ISummaryLoader {
  color: string;
  type: "solid" | "loader" | "outlined" | "cross";
  label?: string;
}
interface IStatusBanner {
  title: string;
  nonExistant: boolean;
  subtitle?: string;
}

const StatusBanner = (props: IStatusBanner) => {
  const isMobile = useSelector(selectIsMobile);
  const orderState = useSelector(selectOrderState);
  const orderSummary = useSelector(selectOrderSummary);
  const orderSummarySupervisor = useSelector(selectLoadOrderSummary);

  const { t } = useTranslation(["header", "ordersummary"]);

  const info: ISummaryBannerInfo = React.useMemo(() => {
    const isTransfer = () => orderSummary?.paymentStatus! >= 200 && orderSummary?.paymentStatus! <= 250;

    if (props.nonExistant) {
      return {
        loaders: [{ color: SummaryColorCode.REJECTED_1, type: "cross" }],
        message: "",
        actualColor: "#484747",
        nextColor: "black",
        subtitle: "Hubo un error encontrando tu orden",
        title: "Oops",
      };
    }
    switch (orderState) {
      case IOrderState.ACCEPTED:
        return {
          loaders: [
            isTransfer() && {
              color: SummaryColorCode.TRANSFER_1,
              type: "solid",
              label: t("ordersummary:orderInfo.accepted.transfer"),
            },
            {
              color: SummaryColorCode.ACCEPTED_1,
              type: "loader",
              label: t("ordersummary:orderInfo.accepted.accepted"),
            },
            {
              color: SummaryColorCode.PREPARATION_1,
              type: "outlined",
              label: t("ordersummary:orderInfo.accepted.preparation"),
            },
            orderSummary?.fulfillmentType === PFXOrderFulfillmentType.IN_PERSON
              ? {
                  color: SummaryColorCode.SHIPPING_1,
                  type: "outlined",
                  label: t("ordersummary:orderInfo.accepted.pickup"),
                }
              : {
                  color: SummaryColorCode.SHIPPING_1,
                  type: "outlined",
                  label: t("ordersummary:orderInfo.accepted.shipping"),
                },
            {
              color: SummaryColorCode.DELIVERED_1,
              type: "outlined",
              label: t("ordersummary:orderInfo.accepted.delivered"),
            },
          ].filter(c => typeof c !== "boolean") as ISummaryLoader[],
          actualColor: SummaryColorCode.ACCEPTED_1,
          nextColor: SummaryColorCode.ACCEPTED_2,
          title: t("ordersummary:orderInfo.accepted.title"),
          subtitle: t("ordersummary:orderInfo.accepted.subtitle"),
          message: `${t("ordersummary:orderInfo.accepted.message")} ${orderSummary?.identification}`,
        };
      case IOrderState.PICKUP:
        return {
          loaders: [
            isTransfer() && {
              color: SummaryColorCode.TRANSFER_1,
              type: "solid",
              label: t("ordersummary:orderInfo.shipping.transfer"),
            },
            { color: SummaryColorCode.ACCEPTED_1, type: "solid", label: t("ordersummary:orderInfo.shipping.accepted") },
            {
              color: SummaryColorCode.PREPARATION_1,
              type: "solid",
              label: t("ordersummary:orderInfo.shipping.preparation"),
            },
            {
              color: SummaryColorCode.SHIPPING_1,
              type: "loader",
              label: t("ordersummary:orderInfo.pickup.pickup"),
            },
            {
              color: SummaryColorCode.DELIVERED_1,
              type: "outlined",
              label: t("ordersummary:orderInfo.shipping.delivered"),
            },
          ].filter(c => typeof c !== "boolean") as ISummaryLoader[],
          actualColor: SummaryColorCode.SHIPPING_1,
          nextColor: SummaryColorCode.SHIPPING_2,
          title: t("ordersummary:orderInfo.pickup.title"),
          subtitle: t("ordersummary:orderInfo.pickup.subtitle"),
          message: `${t("ordersummary:orderInfo.pickup.message")} ${orderSummary?.identification}`,
        };
      case IOrderState.SHIPPING:
        return {
          loaders: [
            isTransfer() && {
              color: SummaryColorCode.TRANSFER_1,
              type: "solid",
              label: t("ordersummary:orderInfo.shipping.transfer"),
            },
            { color: SummaryColorCode.ACCEPTED_1, type: "solid", label: t("ordersummary:orderInfo.shipping.accepted") },
            {
              color: SummaryColorCode.PREPARATION_1,
              type: "solid",
              label: t("ordersummary:orderInfo.shipping.preparation"),
            },
            {
              color: SummaryColorCode.SHIPPING_1,
              type: "loader",
              label: t("ordersummary:orderInfo.shipping.shipping"),
            },
            {
              color: SummaryColorCode.DELIVERED_1,
              type: "outlined",
              label: t("ordersummary:orderInfo.shipping.delivered"),
            },
          ].filter(c => typeof c !== "boolean") as ISummaryLoader[],
          actualColor: SummaryColorCode.SHIPPING_1,
          nextColor: SummaryColorCode.SHIPPING_2,
          title: t("ordersummary:orderInfo.shipping.title"),
          subtitle: t("ordersummary:orderInfo.shipping.subtitle"),
          message: `${t("ordersummary:orderInfo.shipping.message")} ${orderSummary?.identification}`,
        };
      case IOrderState.PREPARATION:
        return {
          loaders: [
            isTransfer() && {
              color: SummaryColorCode.TRANSFER_1,
              type: "solid",
              label: t("ordersummary:orderInfo.preparation.transfer"),
            },
            {
              color: SummaryColorCode.ACCEPTED_1,
              type: "solid",
              label: t("ordersummary:orderInfo.preparation.accepted"),
            },
            {
              color: SummaryColorCode.PREPARATION_1,
              type: "loader",
              label: t("ordersummary:orderInfo.preparation.preparation"),
            },
            orderSummary?.fulfillmentType === PFXOrderFulfillmentType.IN_PERSON
              ? {
                  color: SummaryColorCode.SHIPPING_1,
                  type: "outlined",
                  label: t("ordersummary:orderInfo.preparation.pickup"),
                }
              : {
                  color: SummaryColorCode.SHIPPING_1,
                  type: "outlined",
                  label: t("ordersummary:orderInfo.preparation.shipping"),
                },
            {
              color: SummaryColorCode.DELIVERED_1,
              type: "outlined",
              label: t("ordersummary:orderInfo.preparation.delivered"),
            },
          ].filter(c => typeof c !== "boolean") as ISummaryLoader[],
          actualColor: SummaryColorCode.PREPARATION_1,
          nextColor: SummaryColorCode.PREPARATION_2,
          title: t("ordersummary:orderInfo.preparation.title"),
          subtitle: t("ordersummary:orderInfo.preparation.subtitle"),
          message: `${t("ordersummary:orderInfo.preparation.message")} ${orderSummary?.identification}`,
        };
      case IOrderState.PENDING:
        return {
          loaders: [
            isTransfer() && {
              color: SummaryColorCode.TRANSFER_1,
              type: "solid",
              label: t("ordersummary:orderInfo.pending.accepted"),
            },
            { color: SummaryColorCode.PENDING_1, type: "loader", label: t("ordersummary:orderInfo.pending.pending") },
            {
              color: SummaryColorCode.ACCEPTED_1,
              type: "outlined",
              label: t("ordersummary:orderInfo.pending.accepted"),
            },
            {
              color: SummaryColorCode.PREPARATION_1,
              type: "outlined",
              label: t("ordersummary:orderInfo.pending.preparation"),
            },
            orderSummary?.fulfillmentType === PFXOrderFulfillmentType.IN_PERSON
              ? {
                  color: SummaryColorCode.SHIPPING_1,
                  type: "outlined",
                  label: t("ordersummary:orderInfo.pending.pickup"),
                }
              : {
                  color: SummaryColorCode.SHIPPING_1,
                  type: "outlined",
                  label: t("ordersummary:orderInfo.pending.shipping"),
                },
            {
              color: SummaryColorCode.DELIVERED_1,
              type: "outlined",
              label: t("ordersummary:orderInfo.pending.delivered"),
            },
          ].filter(c => typeof c !== "boolean") as ISummaryLoader[],
          actualColor: SummaryColorCode.PENDING_1,
          nextColor: SummaryColorCode.PENDING_2,
          title: t("ordersummary:orderInfo.pending.title"),
          subtitle: Boolean([103, 403, 603, 903, 1003].find(o => o === orderSummary?.paymentStatus))
            ? t("ordersummary:orderInfo.pending.subtitle1")
            : t("ordersummary:orderInfo.pending.subtitle2"),
          message: Boolean([103, 403, 603, 903, 1003].find(o => o === orderSummary?.paymentStatus))
            ? t("ordersummary:orderInfo.pending.subtitle1")
            : t("ordersummary:orderInfo.pending.subtitle2"),
        };
      case IOrderState.REJECTED:
        return {
          loaders: [{ color: SummaryColorCode.REJECTED_1, type: "cross" }],
          actualColor: SummaryColorCode.REJECTED_1,
          nextColor: SummaryColorCode.REJECTED_2,
          title: t("ordersummary:orderInfo.rejected.title"),
          subtitle: isTransfer()
            ? t("ordersummary:orderInfo.rejected.subtitle1")
            : t("ordersummary:orderInfo.rejected.subtitle2"),
          message: ``,
        };
      case IOrderState.CANCELLED:
        return {
          loaders: [{ color: SummaryColorCode.CANCELLED_1, type: "cross" }],
          actualColor: SummaryColorCode.CANCELLED_1,
          nextColor: SummaryColorCode.CANCELLED_2,
          title: t("ordersummary:orderInfo.cancelled.title"),
          subtitle: "",
          message: ``,
        };
      case IOrderState.TRANSFER:
        return {
          loaders: [],
          actualColor: SummaryColorCode.TRANSFER_1,
          nextColor: SummaryColorCode.TRANSFER_2,
          title: t("ordersummary:orderInfo.transfer.title"),
          subtitle: t("ordersummary:orderInfo.transfer.subtitle"),
          message: ``,
        };
      case IOrderState.DELIVERED:
        return {
          loaders: [
            {
              color: SummaryColorCode.DELIVERED_1,
              type: "solid",
              label: t("ordersummary:orderInfo.delivered.delivered"),
            },
          ] as ISummaryLoader[],
          actualColor: SummaryColorCode.DELIVERED_1,
          nextColor: SummaryColorCode.DELIVERED_2,
          title: t("ordersummary:orderInfo.delivered.title"),
          subtitle: t("ordersummary:orderInfo.delivered.subtitle"),
          message: "",
        };
      default:
        return {
          loaders: [],
          actualColor: SummaryColorCode.ACCEPTED_1,
          nextColor: SummaryColorCode.ACCEPTED_2,
          title: t("ordersummary:orderInfo.error.title"),
          subtitle: "",
          message: "",
        };
    }
  }, [orderState, orderSummary?.identification, orderSummary?.paymentStatus, props.nonExistant]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      "@keyframes banner": {
        "0%": { backgroundPosition: "0% 50%" },
        "50%": { backgroundPosition: "100% 50%" },
        "100%": { backgroundPosition: `0% 50%` },
      },
      "@keyframes strobe": {
        "0%": {
          border: "1pt solid #00000000",
          boxShadow: "none",
        },
        "25%": {
          border: "1pt solid #ffffff29",
          boxShadow: "0pt 0pt 20px 0px #ffffff59",
        },
        "50%": {
          border: "1pt solid #00000000",
          boxShadow: "none",
        },
        "75%": {
          border: "1pt solid #ffffff29",
          boxShadow: "0pt 0pt 20px 0px #ffffff59",
        },
        "100%": {
          border: "1pt solid #00000000",
          boxShadow: "none",
        },
      },
      banner: {
        top: 0,
        width: "100%",
        height: isMobile ? 190 : 190,
        background:
          orderSummarySupervisor.status === "loading"
            ? SummaryColorCode.REJECTED_1
            : `linear-gradient(to right, ${info.actualColor} 40%, ${info.nextColor} 50%)`,
        backgroundSize: "240% 240%",
        borderRadius: isMobile ? 0 : "0px 0px 25px 25px",
        position: "relative",
        boxShadow: "1pt 2pt 4px 0px #0000004d",
        display: "flex",
        justifyContent: "center",
        animationFillMode: "forwards",
        animationDuration: "10s",
        animationIterationCount: "infinite",
        animationName: "$banner",
        animationTimingFunction: "cubic-bezier(0.4, 0, 1, 1)",
      },
      iconButton: {
        position: "absolute",
        color: "white",
        top: 5,
        left: 5,
        border: "1pt solid #00000000",
        "&.strobe": {
          animation: "$strobe 3s",
        },
      },
    })
  );
  const classes = useStyles();

  const handleBackToCatalog = () => {
    const domain = process.env.REACT_APP_PUBLIC_URL!.replace("*", orderSummary!.company!.subdomain!);
    window.location.href = `${domain}/${orderSummary?.cleverlynkId}`;
  };

  return (
    <div className={classes.banner}>
      {orderSummarySupervisor.status !== "idle" && orderSummarySupervisor.status !== "loading" && (
        <React.Fragment>
          <Button
            onClick={handleBackToCatalog}
            className={classes.iconButton}
            id="back-to-catalog"
            startIcon={<ArrowLeft />}>
            <Typography variant="caption">{t("ordersummary:goBackToCatalog")}</Typography>
          </Button>
          <StatusBannerHeader title={props.title} info={info} />
        </React.Fragment>
      )}
    </div>
  );
};
export default StatusBanner;
