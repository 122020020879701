import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import {
  PFXBanner,
  PFXCardType,
  PFXCurrency,
  PFXItem,
  PFXSKU,
  PFXTemplateStylesStructure,
} from "../../models/Interfaces";
import { RootState } from "../rootReducer";

export enum NOT_FOUND {
  CAT = "categoría",
  SUBCAT = "subcategoría",
  ITEM = "producto",
}

export enum PFXTemplateType {
  CAROUSEL = "CAROUSEL",
  DRAWER = "DRAWER",
  PRIMARY = "PRIMARY",
}
export interface IDiscount {
  type: "flat" | "percentage" | "none";
  amount: number;
}

export interface IDiscountInfo {
  discount: IDiscount;
  id: string;
  scope: string;
}

export interface WeekRange {
  mon: number[][];
  tue: number[][];
  wed: number[][];
  thu: number[][];
  fri: number[][];
  sat: number[][];
  sun: number[][];
}

export interface IPriceAndDiscount {
  price: number;
  discount: IDiscount;
}

type ComponentStatusType = "idle" | "loading" | "succeeded" | "failed";
type AsyncState = {
  status: ComponentStatusType;
  error?: string;
};
const initialAsyncState: AsyncState = { status: "idle" };

export interface PFXCategoryItem extends PFXItem {
  priceWithDiscount: number;
}

export interface PFXCategory {
  id: string;
  items: PFXCategoryItem[];
  categories: PFXCategory[];
  parent: string;
  name: string;
  image: string;
  price: string;
  description: string;
  range?: WeekRange | undefined;
  hidden?: boolean;
}

export interface PFXCategoryStructure {
  id: string;
  items?: PFXCategoryItem[];
  categories?: PFXCategory[];
}

export interface ITemplateInfoForRender {
  banners: PFXBanner[];
  cardSize: PFXCardType;
  structure: any;
  items: any;
  categories: any;
  currency: PFXCurrency;
  hideItemsWhenSoldOut: boolean;
  showAllProductsTab: boolean;
  skuList: PFXSKU[];
  socialsFooter: boolean;
  styles: PFXTemplateStylesStructure;
  type: PFXTemplateType;
  drawerNavbarWhenMobile: boolean;
  disableNavbarFade: boolean;
  upsellingIds: string[];
}

interface TemplateState {
  categories: EntityState<PFXCategory>;
  skuList: EntityState<PFXSKU>;
  items: EntityState<PFXCategoryItem>;
  catalog: PFXCategory | undefined;
  banner: PFXBanner | undefined;
  breadCrumb: string;
  categoryIndex: number;
  drawerNavbarWhenMobile: boolean;
  subCategoryIndex: number;
  itemToShow: PFXCategoryItem | undefined;
  showAllProductsTab: boolean;
  hideItemsWhenSoldOut: boolean;
  cardsAlignment: string;
  templateType: PFXTemplateType;
  globalDiscount: number;
  socialFooter: boolean;
  currency: PFXCurrency;
  cardType: PFXCardType;
  templateStyles: PFXTemplateStylesStructure | undefined;
  initCatalog: AsyncState;
  goHome: AsyncState;
  disableNavbarFade: boolean;
  upsellingIds: string[];
  recentlyViewed: string[];
}

const skuAdapters = createEntityAdapter<PFXSKU>({
  selectId: sku => sku.sku!,
  sortComparer: (skuA, skuB) => (skuA?.updatedAt! < skuB?.updatedAt! ? 1 : -1),
});

const itemAdapters = createEntityAdapter<PFXCategoryItem>({
  selectId: it => it?.id!,
  sortComparer: (itA, itB) => (itA.id! > itB.id! ? -1 : 1),
});

const categoryAdapters = createEntityAdapter<PFXCategory>({
  selectId: cat => cat.id!,
  sortComparer: (catA, catB) => (catA.id < catB.id ? -1 : 1),
});

export const initialState: TemplateState = {
  categories: categoryAdapters.getInitialState(),
  skuList: skuAdapters.getInitialState(),
  items: itemAdapters.getInitialState(),
  catalog: undefined,
  banner: undefined,
  breadCrumb: "rootCategory",
  categoryIndex: -1,
  subCategoryIndex: 0,
  itemToShow: undefined,
  showAllProductsTab: true,
  hideItemsWhenSoldOut: false,
  templateType: PFXTemplateType.PRIMARY,
  globalDiscount: 0,
  drawerNavbarWhenMobile: false,
  socialFooter: false,
  currency: PFXCurrency.COP,
  cardType: PFXCardType.NORMAL,
  cardsAlignment: "",
  initCatalog: initialAsyncState,
  templateStyles: undefined,
  disableNavbarFade: false,
  goHome: initialAsyncState,
  upsellingIds: [],
  recentlyViewed: [],
};

export const goHome = createAsyncThunk(
  "template/goHome",
  async (info: { history: any; basePath: string }, thunkApi) => {
    try {
      const { history, basePath } = info;
      return history.push({
        pathname: basePath,
      });
    } catch (error) {
      thunkApi.rejectWithValue("Hubo un error, intentalo nuevamente");
    }
  }
);

const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    setSubCategoryIndex(state, action) {
      state.subCategoryIndex = action.payload.index;
    },
    setCategoryIndex(state, action) {
      state.categoryIndex = action.payload.index;
    },
    setItemToShow(state, action) {
      state.itemToShow = action.payload;
    },
    setSocialsFooter(state, action) {
      state.socialFooter = action.payload;
    },
    setCurrency(state, action) {
      state.currency = action.payload;
    },
    setSkuList(state, action) {
      skuAdapters.setAll(state.skuList, action.payload.skuList);
    },
    addRecentlyViewed(state, action) {
      state.recentlyViewed = Array.from(new Set([action.payload, ...state.recentlyViewed].slice(0, 8)));
    },
    modifyIndividualSku(state, action) {
      skuAdapters.updateOne(state.skuList, {
        id: action.payload.sku.sku,
        changes: { stock: action.payload.sku.stock },
      });
    },
    initCatalog(state, action) {
      const {
        banners,
        cardSize,
        structure,
        currency,
        items,
        categories,
        hideItemsWhenSoldOut,
        showAllProductsTab,
        skuList,
        socialsFooter,
        styles,
        drawerNavbarWhenMobile,
        type,
        disableNavbarFade,
        upsellingIds,
      } = action.payload as ITemplateInfoForRender;

      state.catalog = JSON.parse(structure);
      state.showAllProductsTab = showAllProductsTab;
      state.hideItemsWhenSoldOut = hideItemsWhenSoldOut;
      state.drawerNavbarWhenMobile = drawerNavbarWhenMobile;
      state.templateType = type;
      state.socialFooter = !!socialsFooter;
      state.cardType = cardSize ?? PFXCardType.NORMAL;
      state.templateStyles = styles;
      state.currency = currency;
      state.disableNavbarFade = disableNavbarFade;
      state.upsellingIds = upsellingIds;
      state.recentlyViewed = [];

      let b: any = undefined;
      if (banners) {
        banners.forEach((banner: PFXBanner) => {
          if (banner.type === "top") {
            b = banner;
          }
        });
      }
      state.banner = b;
      itemAdapters.setAll(state.items, JSON.parse(items));
      categoryAdapters.setAll(state.categories, JSON.parse(categories));
      skuAdapters.setAll(state.skuList, skuList);

      state.initCatalog.status = "succeeded";
    },
    changeLocationInPrimaryTemplate(state, action) {
      // console.log({ action, s: { ...state } });
      const moveHomeError = type => {
        // console.log("no se que hacer todavia");
      };
      const { location } = action.payload;
      if (!location.search) {
        state.categoryIndex = -1;
        state.subCategoryIndex = 0;
        if (state.catalog) {
          state.breadCrumb = state.catalog.id!;
        }
      } else {
        const queryParams = new URLSearchParams(location.search);
        let categoryId, subcategoryId, itemId;
        if (queryParams.has("c") && state.catalog) {
          categoryId = queryParams.get("c");
          const categoryIndex = state.catalog?.categories.findIndex(cat => cat.id === categoryId && !cat.hidden);
          if (categoryIndex === -1) moveHomeError(NOT_FOUND.CAT);
          else {
            if (queryParams.has("sc")) {
              subcategoryId = queryParams.get("sc");
              const subcategoryIndex = state.catalog?.categories[categoryIndex!].categories.findIndex(
                subcat => subcat.id === subcategoryId && !subcat.hidden
              );
              if (subcategoryIndex === -1) moveHomeError(NOT_FOUND.SUBCAT);
              else {
                state.categoryIndex = categoryIndex!;
                state.subCategoryIndex = subcategoryIndex! + 1;
                state.breadCrumb = subcategoryId;
              }
            } else {
              state.categoryIndex = categoryIndex!;
              state.subCategoryIndex = state.showAllProductsTab ? 0 : 1;
              state.breadCrumb = categoryId;
            }
          }
        }
        if (queryParams.has("it") && state.catalog) {
          itemId = queryParams.get("it");
          const itemToRender = state.items.entities[itemId];
          if (itemToRender) {
            state.itemToShow = itemToRender;
          } else moveHomeError(NOT_FOUND.ITEM);
        }
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(goHome.pending, state => {
      state.goHome.status = "loading";
    });
    builder.addCase(goHome.rejected, (state, action: any) => {
      state.goHome.status = "failed";
      state.goHome.error = action.payload.error;
    });
    builder.addCase(goHome.fulfilled, (state, action) => {
      state.goHome.status = "succeeded";
      state.breadCrumb = "rootCategory";
      state.categoryIndex = -1;
      state.subCategoryIndex = -1;
      const elem = document.getElementsByTagName("html");
      if (elem && elem[0]) {
        elem[0].scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    });
  },
});

export const {
  setSubCategoryIndex,
  setCategoryIndex,
  modifyIndividualSku,
  setSkuList,
  setItemToShow,
  changeLocationInPrimaryTemplate,
  setSocialsFooter,
  addRecentlyViewed,
  setCurrency,
  initCatalog,
} = templateSlice.actions;

// SELECTORS
export const selectBreadCrumb = (state: RootState) => state.template.breadCrumb;
export const selectItemToShow = (state: RootState) => state.template.itemToShow;
export const {
  selectById: selectCategoryById,
  selectAll: selectCategories,
  selectEntities: selectCategoryEntities,
} = categoryAdapters.getSelectors((state: RootState) => state.template.categories);
export const {
  selectById: selectSkuById,
  selectAll: selectSkus,
  selectEntities: selectSkusEntities,
} = skuAdapters.getSelectors((state: RootState) => state.template.skuList);
export const {
  selectById: selectItemById,
  selectAll: selectItems,
  selectEntities: selectItemsEntities,
  selectTotal: selectItemsAmount,
} = itemAdapters.getSelectors((state: RootState) => state.template.items);
export const selectSocialFooter = (state: RootState) => state.template.socialFooter;
export const selectBanner = (state: RootState) => state.template.banner;
export const selectCatalog = (state: RootState) => state.template.catalog;
export const selectCardType = (state: RootState) => state.template.cardType;
export const selectCategoryIndex = (state: RootState) => state.template.categoryIndex;
export const selectCurrency = (state: RootState) => state.template.currency;
export const selectSubCategoryIndex = (state: RootState) => state.template.subCategoryIndex;
export const selectShowAllProductsTab = (state: RootState) => state.template.showAllProductsTab;
export const selectTemplateType = (state: RootState) => state.template.templateType;
export const selectTemplateStyles = (state: RootState) => state.template.templateStyles;
export const selectCardsAlignment = (state: RootState) => state.template.cardsAlignment;
export const selectHideSoldOutItem = (state: RootState) => state.template.hideItemsWhenSoldOut;
export const selectDrawerNavbarWhenMobile = (state: RootState) => state.template.drawerNavbarWhenMobile;
export const selectInitCatalog = (state: RootState) => state.template.initCatalog;
export const selectDisableNavbarFade = (state: RootState) => state.template.disableNavbarFade;
export const selectUpsellingIds = (state: RootState) => state.template.upsellingIds;
export const selectRecentlyViewed = (state: RootState) => state.template.recentlyViewed;

export default templateSlice.reducer;
