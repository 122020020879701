import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callGraphQL from "../../models/graphql-api";
import { PFXCustomerFormInfo } from "../../models/Interfaces";
import { getCustomerInfo, getCustomerInfoQuery } from "../../models/Queries/GetCustomerInfo";
import { RootState } from "../rootReducer";

type ComponentStatusType = "idle" | "loading" | "succeeded" | "failed";
type AsyncState = {
  status: ComponentStatusType;
  error?: string;
};
const initialAsyncState: AsyncState = { status: "idle" };

interface IUserFormSlice {
  queryCustomerInfo: AsyncState;
  customerInfo: PFXCustomerFormInfo | undefined;
  savedIdentifications: string[];
  openedAuth: boolean;
  updateInfo: boolean;
  consent: boolean;
}

export const initialState: IUserFormSlice = {
  queryCustomerInfo: initialAsyncState,
  customerInfo: undefined,
  savedIdentifications: [],
  openedAuth: false,
  updateInfo: false,
  consent: false,
};

export const queryCustomerInfo = createAsyncThunk(
  "userform/getCustomerInfo",
  async (info: { id: string }, thunkApi) => {
    try {
      const { id } = info;
      const cInfo = await getCustomerInfo(await callGraphQL<any>(getCustomerInfoQuery(id)));
      if (cInfo.email) {
        return { customerInfo: cInfo, updateInfo: true, consent: true };
        // setCustomerInfoConsent({ previousConsent: true, newConsent: false });
      } else throw new Error("No tiene info");
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const userFormSlice = createSlice({
  name: "userform",
  initialState,
  reducers: {
    addIdentifications(state, action) {
      state.savedIdentifications = Array.from(new Set([...state.savedIdentifications, ...action.payload]));
    },
    setOpenedAuth(state) {
      state.openedAuth = true;
    },
    setUpdateInfo(state, action) {
      state.updateInfo = action.payload;
    },
    setConsent(state, action) {
      state.consent = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(queryCustomerInfo.pending, state => {
      state.queryCustomerInfo.status = "loading";
    });
    builder.addCase(queryCustomerInfo.rejected, (state, action: any) => {
      state.queryCustomerInfo.status = "failed";
      state.queryCustomerInfo.error = action.payload.error;
      state.openedAuth = true;
      state.updateInfo = false;
      state.consent = true;
    });
    builder.addCase(queryCustomerInfo.fulfilled, (state, action) => {
      state.customerInfo = (action.payload as any).customerInfo;
      state.updateInfo = true;
      state.consent = true;
      state.queryCustomerInfo.status = "succeeded";
    });
  },
});

export const { addIdentifications, setOpenedAuth, setUpdateInfo, setConsent } = userFormSlice.actions;

export default userFormSlice.reducer;

export const selectCustomerInfo = (state: RootState) => state.userform.customerInfo;
export const selectQueryCustomerInfo = (state: RootState) => state.userform.queryCustomerInfo;
export const selectSavedIdentifications = (state: RootState) => state.userform.savedIdentifications;
export const selectOpenedAuth = (state: RootState) => state.userform.openedAuth;
export const selectUpdateInfo = (state: RootState) => state.userform.updateInfo;
export const selectConsent = (state: RootState) => state.userform.consent;
