import { Box, createStyles, Grid, IconButton, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { Add, Close, Remove } from "@material-ui/icons";
import React, { useMemo } from "react";
import { PFXItemForOrder } from "../../models/Interfaces";
import { currencyFormatter, selectIsMobile } from "../../redux/slices/cleverlynkSlice";
import { IKImage } from "imagekitio-react";
import useCleverlynkFunctions from "../../hooks/useCleverlynkFunctions";
import { useSelector } from "react-redux";
import { selectCurrency } from "../../redux/slices/templateSlice";
import { getOptionsPrice } from "../ShoppingCart/ShoppingCart2";
import fallbackImage from "../../images/fallback_item.png";

interface ICheckoutItemCard {
  item: PFXItemForOrder;
  index: number;
  deleteItem?: () => void;
  inheritBR?: boolean;
}

const CheckoutItemCard = ({ item, deleteItem, index, inheritBR }: ICheckoutItemCard) => {
  const isMobile = useSelector(selectIsMobile);
  const title = isMobile ? "body2" : "body1";
  const option = isMobile ? "caption" : "body2";

  const currency = useSelector(selectCurrency);

  const { renderOptionsAsString, handleModifySingleItemInCart } = useCleverlynkFunctions();

  const path = React.useMemo(
    () =>
      item?.images && item?.images.length > 0 && item?.images[0] !== ""
        ? {
            fallback: false,
            url: item?.images[0].split("cleverlynk-imgs.s3.amazonaws.com")[1]?.replace("resized-", ""),
          }
        : { fallback: true, url: fallbackImage },
    [item?.images]
  );

  const options = useMemo(() => renderOptionsAsString(item.chosenOptions || [], item.subitems ?? [], currency), []);

  const hasDiscount = useMemo(() => {
    const priceWithoutSubitems =
      (item?.price ?? 0) - (item?.chosenOptions ?? []).reduce((a, i) => (i?.value ?? 0) * (i.quantity ?? 0) + a, 0);
    return priceWithoutSubitems !== item.og_price;
  }, [item]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      imgBox: {
        borderRadius: inheritBR ? "inherti" : 5,
        float: "left",
        position: "relative",
        width: "100%",
        margin: "1.66%",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      strikedText: {
        paddingRight: theme.spacing(1),
        textDecoration: "line-through",
        textDecorationColor: "rgba(250, 0, 0)",
      },
      deliveryDelayText: {
        color: theme.palette.warning.main,
      },
    })
  );

  const classes = useStyles();

  const renderPrice = useMemo(
    () => ({
      discount: (
        <React.Fragment>
          <Typography
            className={classes.strikedText}
            variant={"subtitle2"}
            color="primary"
            id={`cart-item-price-${index}`}>
            {currencyFormatter(
              ((item.og_price! ?? 0) + getOptionsPrice(item.chosenOptions || [])) * (item.quantity || 0),
              currency!
            )}
          </Typography>
          <Typography variant={"body1"} display="inline" color="primary" id={`cart-item-price-${index}`}>
            {currencyFormatter(item.price! * (item.quantity || 0), currency!)}
          </Typography>
          {item.quantity! > 1 && (
            <Typography variant="caption">&nbsp;(c/u {currencyFormatter(item.price!, currency!)})</Typography>
          )}
        </React.Fragment>
      ),
      plain: (
        <React.Fragment>
          <Typography variant={"body1"} color="primary" style={{ display: "contents" }} id={`cart-item-price-${index}`}>
            {currencyFormatter(item.price! * (item.quantity || 0), currency!)}
          </Typography>
          {item.quantity! > 1 && (
            <Typography variant="caption">&nbsp;(c/u {currencyFormatter(item.price!, currency!)})</Typography>
          )}
        </React.Fragment>
      ),
    }),
    [classes.strikedText, currency, item.chosenOptions, item.og_price, item.price, item.quantity]
  );

  const renderImage = useMemo(() => {
    return (
      <Box className={classes.imgBox}>
        {path.fallback ? (
          <img
            src={path.url}
            width="70px"
            height="70px"
            alt="fallbackitem"
            style={{
              borderRadius: 10,
              objectFit: "cover",
            }}
          />
        ) : (
          <IKImage
            id={`checkout-card-${item.id}`}
            key={`checkout-card-${item.id}`}
            path={path.url}
            style={{
              width: "80%",
              borderRadius: 10,
            }}
            transformation={[
              {
                height: 125,
                width: 125,
              },
            ]}
            loading="lazy"
          />
        )}
        {/* <IKImage
                key={`checkout-card-${item.id}`}
                style={{
                  width: "80%",
                  borderRadius: 10,
                }}
                path={path}
                transformation={[
                  {
                    height: 125,
                    width: 125,
                  },
                ]}
              /> */}
      </Box>
    );
  }, [classes.imgBox, item.id, path.fallback, path.url]);

  return (
    <Paper
      style={{
        padding: 8,
        margin: "4px 0px",
        position: "relative",
        borderRadius: inheritBR ? "" : 5,
        minHeight: deleteItem ? "" : 128,
      }}
      elevation={1}>
      {deleteItem && (
        <IconButton style={{ position: "absolute", right: 0, top: 0 }} size="small" onClick={deleteItem}>
          <Close fontSize="small" />
        </IconButton>
      )}
      <Grid container alignItems="center" justifyContent="flex-start">
        <Grid item container xs={12}>
          <Grid item xs={3} style={{ maxWidth: 125, maxHeight: 125 }}>
            {renderImage}
          </Grid>
          <Grid item xs={8} container alignContent={isMobile ? "center" : "flex-start"}>
            <Typography
              style={{
                fontSize: isMobile ? "1rem" : "0.9rem",
              }}
              variant={title}
              color="primary"
              display="block"
              id={`cart-item-name-${index}`}>
              {item.name}
            </Typography>
            {!isMobile && (
              <Grid item container direction="column">
                {item.deliveryDelay && item.deliveryDelay > 0 && (
                  <Grid item>
                    <Typography
                      style={{ whiteSpace: "pre-line" }}
                      variant={option}
                      display="block"
                      className={classes.deliveryDelayText}
                      id={`cart-item-delivery-delay-${index}`}>
                      {`Este producto tiene un tiempo de preparación de ${
                        item.deliveryDelay / 24 > 1
                          ? `${item.deliveryDelay / 24} días`
                          : `${item.deliveryDelay / 24} día`
                      }`}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Typography
                    style={{ whiteSpace: "pre-line" }}
                    variant={option}
                    color="textSecondary"
                    display="block"
                    id={`cart-item-options-${index}`}>
                    {options}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} container justifyContent="flex-start" style={{ alignSelf: "stretch" }}>
            <Grid item container direction="column">
              {!!isMobile && item.deliveryDelay && item.deliveryDelay > 0 ? (
                <Grid item>
                  <Typography
                    style={{ whiteSpace: "pre-line" }}
                    variant={option}
                    display="block"
                    className={classes.deliveryDelayText}>
                    {`Tiempo de preparación: ${
                      item.deliveryDelay / 24 > 1 ? `${item.deliveryDelay / 24} días` : `${item.deliveryDelay / 24} día`
                    }`}
                  </Typography>
                </Grid>
              ) : null}
              {!!isMobile && (
                <Grid item>
                  <Typography style={{ whiteSpace: "pre-line" }} variant={option} color="textSecondary" display="block">
                    {options}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item container justifyContent="flex-start" alignItems="center" xs={7} style={{ paddingLeft: 6 }}>
            {hasDiscount ? renderPrice.discount : renderPrice.plain}
          </Grid>
          <Grid item xs={5}>
            {deleteItem && (
              <ItemAmountButton
                quantity={item.quantity ?? 0}
                handleModify={(q: number) => {
                  if (!q) {
                    deleteItem();
                  } else {
                    handleModifySingleItemInCart(q, index);
                  }
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

interface IItemAmountButton {
  quantity: number;
  handleModify: (amnt: number) => void;
}

const ItemAmountButton = ({ quantity, handleModify }: IItemAmountButton) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      amountTypography: {
        fontWeight: "bold",
        textAlign: "center",
        width: "100%",
        fontSize: "1.2rem",
      },
      container: { padding: 3, border: "1pt solid #e2e2e2", borderRadius: 9, maxWidth: 200 },
    })
  );

  const classes = useStyles();

  return (
    <Grid container justifyContent="space-evenly" alignItems="center" className={classes.container}>
      <Grid item xs={3} container justifyContent="center">
        <IconButton onClick={() => handleModify(quantity - 1)} style={{ padding: 3 }}>
          <Remove />
        </IconButton>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.amountTypography}>{quantity}</Typography>
      </Grid>
      <Grid item xs={3} container justifyContent="center">
        <IconButton onClick={() => handleModify(quantity + 1)} style={{ padding: 3 }}>
          <Add />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CheckoutItemCard;
