import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useCleverlynkContext } from "../contexts/CleverlynkContext";
import { PFXCurrency, PFXSubItemChosenOption } from "../models/Interfaces";
import {
  addRecentlyViewed,
  goHome,
  modifyIndividualSku,
  PFXCategoryItem,
  selectBreadCrumb,
  selectCatalog,
  selectHideSoldOutItem,
  selectSkusEntities,
  setCategoryIndex,
  setCurrency,
  setItemToShow,
  setSocialsFooter,
  setSubCategoryIndex,
} from "../redux/slices/templateSlice";

export default function useTemplateFunctions() {
  const breadCrumb = useSelector(selectBreadCrumb);
  const hideItemsWhenSoldOut = useSelector(selectHideSoldOutItem);
  const catalog = useSelector(selectCatalog);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const skus = useSelector(selectSkusEntities);

  const cleverlynkContext = useCleverlynkContext();
  const basePath = React.useMemo(() => cleverlynkContext?.basePath ?? "", [cleverlynkContext]);

  const handleModifySubCategoryIndex = (index: number) => dispatch(setSubCategoryIndex({ index }));
  const handleAddRecentlyViewed = (id: string) => dispatch(addRecentlyViewed(id));
  const handleModifyCategoryIndex = (index: number) => dispatch(setCategoryIndex({ index }));
  const handleModifyIndividualSku = (sku, stock) => dispatch(modifyIndividualSku({ sku: { sku, stock } }));
  const handleGoHome = () => {
    dispatch(goHome({ history, basePath }));
    // handleSetCleverlynkStage(1);
  };
  const handleModifyItemToShow = (item: PFXCategoryItem | undefined) => dispatch(setItemToShow(item));
  const handleModifySocialsFooter = (sf: boolean) => {
    dispatch(setSocialsFooter(sf));
  };
  const handleModifyCurrency = (currency: PFXCurrency) => {
    dispatch(setCurrency(currency));
  };

  const compareSubitems = (a: PFXSubItemChosenOption[], b: PFXSubItemChosenOption[]) => {
    if (a.length !== b.length) return false;
    let equal = true;
    a.forEach(a_option => {
      if (!b.find(b_option => a_option.id === b_option.id && b_option.quantity === a_option.quantity)) {
        equal = false;
      }
    });
    return equal;
  };

  const shouldHideSoldOutItem = (item: PFXCategoryItem) => {
    return Boolean(hideItemsWhenSoldOut) && skus[item.sku ?? ""]?.stock === 0;
  };

  const currentCategoryDepth = React.useMemo(() => {
    let q = [{ cat: catalog, depth: 0 }];
    while (q.length !== 0) {
      let temp = q.pop();
      if (temp && temp.cat) {
        if (temp.cat!.id === breadCrumb) {
          return temp.depth;
        } else {
          let nd = temp!.depth + 1;
          q = [...(temp.cat.categories ?? []).map(c => ({ cat: c, depth: nd })), ...q];
        }
      }
    }
    return 0;
  }, [breadCrumb, catalog]);

  const closeItemDialog = () => {
    if (!location.pathname.endsWith("/purchase") && !location.pathname.endsWith("/info")) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete("it");
      history.push({
        pathname: `${basePath}/menu`,
        search: queryParams.toString(),
      });
    }
  };

  return {
    handleGoHome,
    handleModifyIndividualSku,
    handleModifySubCategoryIndex,
    handleModifyCategoryIndex,
    handleModifyItemToShow,
    closeItemDialog,
    handleModifySocialsFooter,
    handleAddRecentlyViewed,
    handleModifyCurrency,
    currentCategoryDepth,
    shouldHideSoldOutItem,
    compareSubitems,
  };
}
