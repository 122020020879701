import { useTheme, useMediaQuery, Paper, Typography, Grid, Box, IconButton, Button, Tooltip } from "@material-ui/core";
import { ChevronRight, Share } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useCleverlynkFunctions from "../../hooks/useCleverlynkFunctions";
import useTemplateFunctions from "../../hooks/useTemplateFunctions";
import useTemplateStyles from "../../hooks/useTemplateStyles";
import { PFXItemForOrder } from "../../models/Interfaces";
import { selectPrice } from "../../redux/slices/checkoutSlice";
import {
  selectCleverlynk,
  selectItemsInCart,
  selectCompany,
  selectCleverlynkStage,
  selectUnavailable,
  currencyFormatter,
} from "../../redux/slices/cleverlynkSlice";
import { selectCurrency, selectSkusEntities } from "../../redux/slices/templateSlice";
import CheckoutItemCard from "../Checkout/CheckoutItemCard";
import CheckoutConfirmationDialog, { CheckoutConfirmationDialogProps } from "../Tools/CheckoutConfirmationDialog";
import CartCompanyInfo from "./CartCompanyInfo";

import { useTranslation } from "react-i18next";
import "../../i18n/config";

interface IFilledCart {}

const FilledCart = (props: IFilledCart) => {
  const [confirmationDialog, setConfirmationDialog] = useState<CheckoutConfirmationDialogProps | null>(null);

  const price = useSelector(selectPrice);
  const cleverlynk = useSelector(selectCleverlynk);
  const currency = useSelector(selectCurrency);
  const itemsInCart = useSelector(selectItemsInCart);
  const company = useSelector(selectCompany);
  const cleverlynkStage = useSelector(selectCleverlynkStage);
  const unavailable = useSelector(selectUnavailable);

  const theme = useTheme();
  const skus = useSelector(selectSkusEntities);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation("header");

  const { handleModifyIndividualSku } = useTemplateFunctions();
  const { handleModifyItemsInCart, renderOptionsAsString, gotToUserForm } = useCleverlynkFunctions();

  const renderPaymentSummary = useMemo(() => {
    if (itemsInCart.length === 0) return null;
    return (
      <Grid container direction="column" style={{ marginTop: 20, marginBottom: 100 }}>
        <Typography color="primary" variant="h5" display="block">
          {t("shoppingCart.summary")}
        </Typography>
        <Grid container item>
          <Grid container item justifyContent="space-between" xs={12} style={{ marginTop: 8 }}>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {t("shoppingCart.subtotal")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {currencyFormatter(price?.subTotal || 0, currency!)}
              </Typography>
            </Grid>
          </Grid>

          {(price?.tax ?? 0) > 0 && (
            <Grid container item justify="space-between" xs={12} style={{ marginTop: 8 }}>
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {t("shoppingCart.tax")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {currencyFormatter(price?.tax ?? 0, currency!)}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid container item justify="space-between" xs={12} style={{ marginTop: 8 }}>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {t("shoppingCart.discount")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {currencyFormatter(price?.discount ?? 0, currency!) || 0}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={1}>
          <Grid container justifyContent="space-between" style={{ borderBottom: "solid 1.5px #1a1a1a" }}>
            <Grid item>
              <Typography variant="body2" style={{ fontSize: "1.1rem" }}>
                Total
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" style={{ fontSize: "1.1rem" }} id="cart-total">
                {currencyFormatter(price.total ?? 0, currency!)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  }, [currency, itemsInCart.length, price?.discount, price?.subTotal, price.total]);

  const deleteItem = (item: PFXItemForOrder, index: number) => {
    let text = "¿Deseas eliminar " + (item.quantity! > 1 ? "los " + item.quantity + " " : "el ") + item.name + "?";
    text += itemsInCart.length === 1 ? "\nSi te quedas sin productos te redireccionaremos al cátalogo" : "";

    const onClose = (result: any) => {
      if (result && cleverlynk) {
        handleModifyItemsInCart([...itemsInCart.filter((_, i) => i !== index)]);
      }
      if (result) handleModifyItemsInCart([...itemsInCart.filter((_, i) => i !== index)]);
      item.chosenOptions
        ?.filter(co => co.controlsInventory)
        .forEach(co => {
          if (skus[co.sku!]) {
            handleModifyIndividualSku(co.sku, (skus[co.sku!]?.stock ?? 0) + co.quantity! * item.quantity!);
          }
        });
      if (item.sku) {
        let newValue = skus[item.sku!]!.stock ?? 0;
        newValue = newValue + item.quantity!;
        handleModifyIndividualSku(item.sku!, newValue);
      }
      setConfirmationDialog(null);
    };

    setConfirmationDialog({
      open: true,
      title: "¿Eliminar producto?",
      text: text,
      confirmText: "Sí",
      cancelText: "No",
      onClose: onClose,
      toggleSnackbar: () => {},
    });
  };

  return (
    <React.Fragment>
      <CartCompanyInfo />
      <Grid container direction="column" style={{ marginTop: 20 }}>
        {itemsInCart.map((item, index) => (
          <CheckoutItemCard
            deleteItem={() => deleteItem(item, index)}
            item={item}
            index={index}
            key={
              item.id! + item.quantity! + renderOptionsAsString(item.chosenOptions || [], item.subitems ?? []).join("")
            }
          />
        ))}
      </Grid>
      {renderPaymentSummary}
      <CheckoutConfirmationDialog
        open={confirmationDialog?.open ?? false}
        title={confirmationDialog?.title}
        text={confirmationDialog?.text}
        image={confirmationDialog?.image}
        confirmText={confirmationDialog?.confirmText}
        cancelText={confirmationDialog?.cancelText}
        onClose={confirmationDialog?.onClose}
        toggleSnackbar={() => {}}
      />
    </React.Fragment>
  );
};
export default FilledCart;
