import { Avatar } from "@material-ui/core";
import { AccountBalance, AddShoppingCart, CreditCard, Money } from "@material-ui/icons";
import { cloneDeep } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import epaycoLogo from "../images/epayco40x40.jpeg";
import mercadopagoLogo from "../images/mercadopago40x40.jpeg";
import paymentezLogo from "../images/paymentez.png";
import pseLogo from "../images/pse.png";
import redebanLogo from "../images/redeban40x40.jpeg";
import wompiLogo from "../images/Wompi40x40.png";
import stripeLogo from "../images/stripeLogo.png";
import payULogo from "../images/payU.png";
import rappiLogo from "../images/rappi.png";
import payzenLogo from "../images/payzen40x40.png";
import { useHistory, useLocation } from "react-router-dom";
import { useCleverlynkContext } from "../contexts/CleverlynkContext";
import {
  PFXCleverlynk,
  PFXCredentialsResponse,
  PFXCurrency,
  PFXItemForOrder,
  PFXPaymentMethod,
  PFXSubItemChosenOption,
  PFXSubItemData,
  PFXOrderFulfillmentType,
} from "../models/Interfaces";
import { ISaveOrder } from "../pages/CleverlynkCheckout";
import {
  currencyFormatter,
  getOrder,
  selectCleverlynk,
  selectCleverlynkGroup,
  selectDeliveryCheckoutInfo,
  selectIdentification,
  selectItemsInCart,
  selectPrice,
  selectCurrentClynkWithinGroup,
  setCleverlynkStage,
  setItemsInCart,
  setOpenGeo,
  // attemptCleverlynkPin,
  setSnackbar,
  setCartIsOpen,
  decreaseCleverlynkStage,
  wipeState,
  updateItemInCart,
  deleteItemInCart,
  setCurrentClynkWithinGroup,
  swapItemsBetweenCleverlynks,
  selectMandatoryCriteria,
  selectOptionalCriteria,
  selectCleverlynkStage,
  selectCompany,
  selectOrder,
  IUnavailableDialog,
  setUnavailable,
  setConfirmLocationLater,
  createOrder,
  createAbandonedCartOrder,
  setOrderState,
  loadOrderSummary,
  createCart,
  modifyTriggerNavToUserForm,
  modifyLoadedLocalStorage,
  setTransferOrderImage,
  updatePrice,
  modifySingleItemInCart,
  setIsMobile,
  getAsyncDeliveryPrice,
  modifyDeliveryGeoInfo,
  IDeliveryCheckoutInfo,
  getActiveGroupMembers,
  modifyOrderSummary,
  setOpenedUpselling,
  selectMandatoryCriteriaEntities,
  selectOptionalCriteriaEntities,
  selectDeliveryType,
  TAKEOUT_LABEL,
  setNoLanding,
} from "../redux/slices/cleverlynkSlice";
import {
  PFXCategoryItem,
  selectCurrency,
  selectItems,
  selectSkus,
  changeLocationInPrimaryTemplate,
} from "../redux/slices/templateSlice";
import useLocalStorageFunction from "./useLocalStorageFunctions";
import useTemplateFunctions from "./useTemplateFunctions";
import { IOrderState } from "../pages/OrderSummary";
import { modifyDeliveryInfo, modifySetSelectedAddress, selectDeliveryInfo } from "../redux/slices/mapServiceSlice";
import { ISelectedCleverlynk } from "../components/CleverlynkGroup/GeoSelector";
import useTemplateStyles from "./useTemplateStyles";

import { useTranslation } from "react-i18next";
import "../i18n/config";
import useDisableOverflow from "./useDisableOverflow";
import { scrollToCat } from "../components/Templates/TemplateTypes/DrawerTemplate";
import {
  selectCleverlynkPromoCodeId,
  selectCustomerPromoCodeId,
  selectPromoCodeId,
} from "../redux/slices/checkoutSlice";

export interface PaymentMethodElem {
  name: string;
  avatar: JSX.Element;
  description: string;
  key: string;
  paymentMethod?: PFXPaymentMethod;
}

export default function useCleverlynkFunctions() {
  const cleverlynkContext = useCleverlynkContext();
  const basePath = useMemo(() => cleverlynkContext?.basePath ?? "", [cleverlynkContext]);
  const mandatoryCriteria = useSelector(selectMandatoryCriteria);
  const optionalCriteria = useSelector(selectOptionalCriteria);
  const mandatoryCriteriaEntities = useSelector(selectMandatoryCriteriaEntities);
  const optionalCriteriaEntities = useSelector(selectOptionalCriteriaEntities);
  const identification = useSelector(selectIdentification);
  const cleverlynkStage = useSelector(selectCleverlynkStage);
  const deliveryType = useSelector(selectDeliveryType);
  const price = useSelector(selectPrice);
  const deliveryCheckoutInfo = useSelector(selectDeliveryCheckoutInfo);
  const deliveryInfo = useSelector(selectDeliveryInfo);
  const cleverlynk = useSelector(selectCleverlynk);
  const chosenClynkWithinGroup = useSelector(selectCurrentClynkWithinGroup);
  const company = useSelector(selectCompany);
  const itemsInCart = useSelector(selectItemsInCart);
  const currency = useSelector(selectCurrency);
  const cleverlynkGroup = useSelector(selectCleverlynkGroup);
  const catalogItems = useSelector(selectItems);
  const skuList = useSelector(selectSkus);
  const order = useSelector(selectOrder);
  const promoCodeId = useSelector(selectPromoCodeId);
  const cleverlynkPromoCodeId = useSelector(selectCleverlynkPromoCodeId);
  const customerPromoCodeId = useSelector(selectCustomerPromoCodeId);
  const { wipeLocalStorage } = useLocalStorageFunction();
  const { compareSubitems, handleModifySocialsFooter, handleModifyCurrency } = useTemplateFunctions();

  const history = useHistory();
  const dispatch = useDispatch();

  const { enable } = useDisableOverflow();

  const { primaryColor, navbarStyles } = useTemplateStyles();

  const handleModifyItemsInCart = (itms: PFXItemForOrder[]) => dispatch(setItemsInCart({ items: itms }));
  const handleModifySingleItemInCart = (quantity: number, index: number) =>
    dispatch(modifySingleItemInCart({ quantity, index }));
  const handleModifyOpenGeo = (open: boolean) => dispatch(setOpenGeo(open));
  const handleModifySnackbar = snckbr => dispatch(setSnackbar(snckbr));
  const handleSetIsMobile = (mobile: boolean) => dispatch(setIsMobile(mobile));
  const handleModifyDeliveryCheckoutInfo = (dgi: Partial<IDeliveryCheckoutInfo>) =>
    dispatch(modifyDeliveryGeoInfo(dgi));
  const handleSetOpenedUpselling = () => dispatch(setOpenedUpselling());
  const handleModifyCartIsOpen = open => dispatch(setCartIsOpen(open));
  const handleWipeState = () => dispatch(wipeState());
  const handleModifyCurrentClynkWithinGroup = ugi => dispatch(setCurrentClynkWithinGroup(ugi));
  const handleModifyOrderSummary = os => dispatch(modifyOrderSummary(os));
  const handleModifyLoadedLocalStorage = (has: boolean) => dispatch(modifyLoadedLocalStorage(has));
  const handleModifyTriggerNavToUserForm = (go: boolean) => dispatch(modifyTriggerNavToUserForm(go));
  const handleModifyUnavailable = (unavailable: Partial<IUnavailableDialog> | undefined) =>
    dispatch(setUnavailable(unavailable));
  const handleSetTransferOrderPhoto = (image: string, orderId: string) =>
    dispatch(setTransferOrderImage({ image, orderId }));
  const handleSetConfirmLocationLater = (confirm: boolean) => dispatch(setConfirmLocationLater(confirm));
  const handleModifyOrderState = (state: IOrderState) => dispatch(setOrderState(state));
  const handleSetCleverlynkStage = (stage: number, toggleNavToForm?: boolean) =>
    dispatch(setCleverlynkStage({ stage, toggleNavToForm }));
  const handleLoadOrderSummary = (orderId: string) =>
    dispatch(loadOrderSummary({ orderId, handleModifySocialsFooter, handleModifyCurrency }));
  const handleUpdatePrice = (items: PFXItemForOrder[]) => dispatch(updatePrice({ items, currency }));
  const handleGetActiveGroupMembers = (groupId: string) => dispatch(getActiveGroupMembers({ groupId }));
  const handleSetNoLandings = (b: boolean) => dispatch(setNoLanding(b));

  const { t } = useTranslation("checkout");

  const handleChangeLocationInPrimaryTemplate = location => dispatch(changeLocationInPrimaryTemplate(location));
  const handleChangeLocationInDrawerTemplate = location => {
    setTimeout(() => {
      const queryParams = new URLSearchParams(location.search);
      const catId = queryParams.has("sc")
        ? queryParams.get("sc")
        : queryParams.has("c")
        ? queryParams.get("c")
        : undefined;
      if (catId !== undefined) {
        scrollToCat(catId, navbarStyles?.headerColor);
      }
    }, 1500);
  };

  const handleSwapItemsBetweenCleverlynks = useCallback(
    (clynkId: string, shouldNavigateToForm: boolean) => {
      if (shouldNavigateToForm) {
        handleSetCleverlynkStage(2, shouldNavigateToForm);
        let path = `${basePath}/info`;
        history.push({
          pathname: path.replace("//", "/"),
        });
      }
      return dispatch(
        swapItemsBetweenCleverlynks({
          itemsInCart,
          cleverlynkGroup,
          clynkId,
          wipeLocalStorage,
        })
      );
    },
    [basePath, cleverlynkGroup, handleSetCleverlynkStage, itemsInCart, wipeLocalStorage]
  );

  const handleRedirectToClynk = useCallback(
    (geoInfo: ISelectedCleverlynk) => {
      if (itemsInCart.length > 0) {
        handleSwapItemsBetweenCleverlynks(geoInfo.id, geoInfo.navigateToForm);
      }
      handleModifyCurrentClynkWithinGroup(geoInfo);
      handleModifyOpenGeo(false);
    },
    [itemsInCart, handleSwapItemsBetweenCleverlynks]
  );

  const handleUpdateItemInCart = (item: PFXItemForOrder) => dispatch(updateItemInCart({ item }));
  const handleDeleteItemInCart = (index: number) => dispatch(deleteItemInCart({ index }));
  const handleGetAsyncDeliveryPrice = (deliveryDate, paymentMethod, credentials) =>
    dispatch(
      getAsyncDeliveryPrice({
        price: price.subTotal ?? 0,
        deliveryDate,
        paymentMethod,
        cleverlynk: cleverlynk!,
        deliveryInfo,
        credentials,
        companyId: company?.id!,
      })
    );

  const handleGoToPreviousStage = () => {
    switch (cleverlynkStage) {
      case 2:
        history.push({
          pathname: basePath,
        });
        break;
      case 3:
        history.push({
          pathname: basePath === "/" ? `${basePath}info`.replace("//", "/") : `${basePath}/info`.replace("//", "/"),
        });
        break;
    }
    enable();
    dispatch(decreaseCleverlynkStage());
  };

  interface IAddBatch {
    items: { quantity: number; subitems: PFXSubItemChosenOption[]; catalogItem: PFXCategoryItem }[];
    callback;
  }

  const addBatchItemsToCart = (params: IAddBatch) => {
    const { items, callback } = params;
    const newItems = cloneDeep(itemsInCart);
    items.forEach(item => {
      const { quantity, subitems, catalogItem } = item;
      const optionsPrice = subitems.reduce(
        (acc, currentValue) => (!currentValue.subitemType ? acc + currentValue.value! * currentValue.quantity! : acc),
        0
      );

      const newItem: PFXItemForOrder = {
        id: catalogItem.id,
        price: catalogItem.priceWithDiscount + optionsPrice,
        name: catalogItem.name,
        quantity: quantity,
        subitems: catalogItem.subitems,
        images: catalogItem.images,
        posReference: catalogItem.posReference,
        og_price: catalogItem.price,
        sku: catalogItem.sku,
        chosenOptions: subitems,
        deliveryDelay: catalogItem.deliveryDelay,
        maxOrderHour: catalogItem.maxOrderHour,
      };
      const itemIndex = newItems.findIndex(
        itemInCart =>
          itemInCart.id === newItem.id && compareSubitems(itemInCart.chosenOptions ?? [], newItem.chosenOptions || [])
      );

      if (itemIndex > -1 && newItems) newItems[itemIndex]!.quantity! += newItem.quantity!;
      else newItems.push(newItem);
    });
    handleModifyItemsInCart(newItems);
    callback();
  };

  const addItemToCart = (
    quantity: number,
    subitems: PFXSubItemChosenOption[],
    catalogItem: PFXCategoryItem,
    cbk: Function
  ) => {
    const optionsPrice = subitems.reduce(
      (acc, currentValue) => (!currentValue.subitemType ? acc + currentValue.value! * currentValue.quantity! : acc),
      0
    );

    const newItem: PFXItemForOrder = {
      id: catalogItem.id,
      price: catalogItem.priceWithDiscount + optionsPrice,
      name: catalogItem.name,
      quantity: quantity,
      subitems: catalogItem.subitems,
      images: catalogItem.images,
      posReference: catalogItem.posReference,
      og_price: catalogItem.price,
      sku: catalogItem.sku,
      chosenOptions: subitems,
      deliveryDelay: catalogItem.deliveryDelay,
      maxOrderHour: catalogItem.maxOrderHour,
    };
    const newItems = cloneDeep(itemsInCart);
    const itemIndex = newItems.findIndex(
      itemInCart =>
        itemInCart.id === newItem.id && compareSubitems(itemInCart.chosenOptions ?? [], newItem.chosenOptions || [])
    );

    if (itemIndex > -1 && newItems) newItems[itemIndex]!.quantity! += newItem.quantity!;
    else newItems.push(newItem);
    handleModifyItemsInCart(newItems);
    cbk();
  };

  const gotToUserForm = () => {
    if (itemsInCart.length === 0) {
      handleModifySnackbar({
        type: "warning",
        message: "Debes agregar al menos 1 artículo",
      });
    } else {
      if (cleverlynk?.minimumAmount && cleverlynk?.minimumAmount > price.total) {
        handleModifySnackbar({
          type: "warning",
          message: `El valor mínimo debe ser ${currencyFormatter(
            cleverlynk?.minimumAmount,
            cleverlynk?.template?.currency!
          )}`,
        });
      } else if (!chosenClynkWithinGroup && cleverlynkGroup) {
        handleModifyOpenGeo(true);
      } else {
        handleSetCleverlynkStage(2);
        history.push({
          pathname: basePath === "/" ? `${basePath}info`.replace("//", " /") : `${basePath}/info`.replace("//", " /"),
        });
      }
    }
  };

  const handleGetOrder = ({ isStart, orderId, addDiscount }) => {
    const loadDeliveryInfo = (d: any | undefined) => {
      if (!!d) {
        dispatch(
          modifyDeliveryInfo({
            address: d.deliveryAddress ?? "",
            coordinates: d.deliveryLocation ?? "",
            additionalInfo: d.deliveryAdditionalInfo ?? "",
            propertyInfo: d.deliveryPropertyTypeInfo ?? "",
          })
        );
        dispatch(modifySetSelectedAddress(true));
      }
    };
    dispatch(
      getOrder({
        isStart,
        orderId,
        skuList,
        catalogItems,
        addDiscount,
        setSnackbar: handleModifySnackbar,
        loadDeliveryInfo,
      })
    );
  };

  const currencyAwareRound = (num: number) => {
    return currency === PFXCurrency.COP ? Math.round(num) : Math.round(num * 100) / 100;
  };

  // const handleAttemptCleverlynkPin = (hash: string) => {
  //   const id = location.pathname.split("/")[1];
  //   dispatch(
  //     attemptCleverlynkPin({
  //       id,
  //       hash,
  //       initCatalog: (params: ITemplateInfoForRender) => dispatch(initCatalog({ ...params })),
  //     })
  //   );
  // };

  const renderOptionsAsString = (
    options: PFXSubItemChosenOption[],
    subitems: PFXSubItemData[],
    currency?: PFXCurrency
  ) => {
    let actualSubitem = "";
    return [...options]
      .sort((a, b) => (a.subitemId! > b.subitemId! ? 1 : -1))
      .filter(o => !o.subitemType || o.input?.trim() !== "")
      .map(o => {
        const price = currencyFormatter(o.value!, currency);
        const subitemName = () => {
          if (o.subitemId !== actualSubitem) {
            actualSubitem = o.subitemId!;
            const n = subitems.find(s => s.id === o.subitemId);
            return n ? n.name + " \n" : "";
          } else return "";
        };
        return !o.subitemType
          ? `${subitemName()} - ${o.quantity! > 1 ? `[${o.quantity}] ` : ""}${o.label}${
              o.value! > 0 ? ` (${price})` : ""
            }\n`
          : `${subitemName()} - ${o.input}\n`;
      });
  };

  const generateDescription = (items: PFXItemForOrder[], currency: PFXCurrency) => {
    let description = "\n";
    items.forEach(item => {
      const price = item.price;
      description += `${item?.name?.toUpperCase()} X + ${item.quantity} : ${price} COP \n`;
      description += renderOptionsAsString(item.chosenOptions || [], item.subitems ?? [], currency);
      description += "\n";
    });
    return description;
  };

  const getPaymentMethods = (cleverlynk: PFXCleverlynk, takeout: boolean, credentials: PFXCredentialsResponse) => {
    const paymentsMethods: PaymentMethodElem[] = [];

    let payCash = true;
    if (cleverlynk?.companyId === "IhRbRNxe6N" || cleverlynk?.companyId === "CqbDnkjbWO") payCash = takeout;

    if (cleverlynk?.paymentMethods?.showroom)
      paymentsMethods.push({
        name: t("paymentMethod.selectionName"),
        description: t("paymentMethod.selectionDescription"),
        avatar: (
          <Avatar alt="Selección" style={{ backgroundColor: primaryColor }} id="selection">
            <AddShoppingCart />
          </Avatar>
        ),
        key: "Selección",
      });
    if (cleverlynk?.paymentMethods?.cash && payCash)
      paymentsMethods.push({
        name: t("paymentMethod.cashName"),
        description: t("paymentMethod.cashDescription"),
        avatar: (
          <Avatar alt="Efectivo" style={{ backgroundColor: primaryColor }} id="cash">
            <Money />
          </Avatar>
        ),
        key: "Efectivo",
        paymentMethod: PFXPaymentMethod.CASH,
      });
    if (cleverlynk?.paymentMethods?.dataphone)
      paymentsMethods.push({
        name: t("paymentMethod.dataphoneName"),
        description: t("paymentMethod.dataphoneDescription"),
        avatar: (
          <Avatar alt="Datáfono" style={{ backgroundColor: primaryColor }} id="dataphone">
            <CreditCard />
          </Avatar>
        ),
        key: "Datafono",
        paymentMethod: PFXPaymentMethod.DATAPHONE,
      });
    if (cleverlynk?.paymentMethods?.bankTransfer)
      paymentsMethods.push({
        name: t("paymentMethod.transferName"),
        description: t("paymentMethod.transferDescription"),
        avatar: (
          <Avatar alt="Transferencia" style={{ backgroundColor: primaryColor }} id="transfer">
            <AccountBalance />
          </Avatar>
        ),
        key: "Transferencia",
        paymentMethod: PFXPaymentMethod.ONLINE,
      });
    // if (cleverlynk?.paymentMethods?.nequi)
    //   paymentsMethods.push({
    //     name: "Nequi",
    //     description: "Pagas a una cuenta de nequi y envias foto del comprobante",
    //     avatar: <Avatar alt="Selección" src={neqs} />,
    //     key: "Nequi",
    //   });
    if (cleverlynk?.paymentMethods?.ePayCo)
      paymentsMethods.push({
        name: t("paymentMethod.cardPse"),
        description: "ePayCo",
        avatar: <Avatar alt="ePayCo" src={epaycoLogo} id="ePayCo" />,
        key: "ePayCo",
        paymentMethod: PFXPaymentMethod.ONLINE,
      });
    if (cleverlynk?.paymentMethods?.redeban) {
      if (credentials?.RB?.pse)
        paymentsMethods.push({
          name: t("paymentMethod.pse"),
          description: "Redeban PSE",
          avatar: <Avatar alt="RedebanPSE" src={pseLogo} id="redebanPSE" />,
          key: "RedebanPSE",
          paymentMethod: PFXPaymentMethod.ONLINE,
        });
      if (credentials?.RB?.card)
        paymentsMethods.push({
          name: t("paymentMethod.card"),
          description: "Redeban Tarjeta",
          avatar: <Avatar alt="RedebanTarjeta" src={redebanLogo} id="redebanCard" />,
          key: "RedebanTarjeta",
          paymentMethod: PFXPaymentMethod.ONLINE,
        });
    }
    if (cleverlynk?.paymentMethods?.paymentez) {
      paymentsMethods.push({
        name: t("paymentMethod.card"),
        description: "Paymentez Tarjeta",
        avatar: <Avatar alt="PaymentezTarjeta" src={paymentezLogo} id="paymentezCard" />,
        key: "PaymentezTarjeta",
        paymentMethod: PFXPaymentMethod.ONLINE,
      });
    }
    if (cleverlynk?.paymentMethods?.mercadoPago)
      paymentsMethods.push({
        name: t("paymentMethod.cardPse"),
        description: "MercadoPago",
        avatar: <Avatar alt="Mercado Pago" src={mercadopagoLogo} id="mercadoPago" />,
        key: "MercadoPago",
        paymentMethod: PFXPaymentMethod.ONLINE,
      });
    if (cleverlynk?.paymentMethods?.wompi)
      paymentsMethods.push({
        name: t("paymentMethod.cardPse"),
        description: "Wompi",
        avatar: <Avatar alt="Wompi" src={wompiLogo} id="wompi" />,
        key: "Wompi",
        paymentMethod: PFXPaymentMethod.ONLINE,
      });
    if (cleverlynk?.paymentMethods?.payzen)
      paymentsMethods.push({
        name: t("paymentMethod.cardPse"),
        description: "Payzen",
        avatar: <Avatar alt="Payzen" src={payzenLogo} id="payzen" />,
        key: "Payzen",
        paymentMethod: PFXPaymentMethod.ONLINE,
      });
    if (cleverlynk?.paymentMethods?.payU)
      paymentsMethods.push({
        name: t("paymentMethod.cardPse"),
        description: "PayU",
        avatar: <Avatar alt="PayU" src={payULogo} id="payU" />,
        key: "PayU",
        paymentMethod: PFXPaymentMethod.ONLINE,
      });
    if (cleverlynk?.paymentMethods?.stripe)
      paymentsMethods.push({
        name: t("paymentMethod.card"),
        description: "Stripe",
        avatar: <Avatar alt="Stripe" src={stripeLogo} id="stripe" />,
        key: "Stripe",
        paymentMethod: PFXPaymentMethod.ONLINE,
      });
    if (cleverlynk?.paymentMethods?.rappi)
      paymentsMethods.push({
        name: t("paymentMethod.payWithRappi"),
        description: "Rappi",
        avatar: <Avatar alt="Rappi" src={rappiLogo} id="rappi" />,
        key: "Rappi",
        paymentMethod: PFXPaymentMethod.ONLINE,
      });

    return paymentsMethods;
  };

  const handleCreateOrder = async ({
    paymentMethod,
    price,
    items,
    deliveryDate,
    deliveryHourRange,
    userMarketingConsent,
    googleClientId,
  }: Partial<ISaveOrder>) => {
    const sortedMC: any =
      (cleverlynk?.criteria ?? []).map(c => mandatoryCriteriaEntities[c.label ?? ""]).filter(cc => !!cc) ?? [];

    if (mandatoryCriteriaEntities[TAKEOUT_LABEL]) {
      sortedMC.push(mandatoryCriteriaEntities[TAKEOUT_LABEL]);
    }
    const sortedOC: any =
      (cleverlynk?.criteria ?? []).map(c => optionalCriteriaEntities[c.label ?? ""]).filter(cc => !!cc) ?? [];

    dispatch(
      createOrder({
        paymentMethod: paymentMethod!,
        price,
        items: items!,
        deliveryDate,
        deliveryHourRange,
        deliveryCheckoutInfo,
        deliveryInfo,
        takeout: deliveryType === PFXOrderFulfillmentType.TAKEOUT,
        cleverlynkId: cleverlynk?.id!,
        companyId: company?.id!,
        currency,
        extras: order?.extras,
        identification,
        mandatoryCriteria: sortedMC,
        optionalCriteria: sortedOC,
        userMarketingConsent,
        googleClientId,
        fulfillmentType: cleverlynk?.inPerson ? PFXOrderFulfillmentType.IN_PERSON : deliveryType,
        cleverlynkPromoCodeId,
        customerPromoCodeId,
        promoCodeId,
      })
    );
  };

  const handleCreateSendOrder = async ({
    price,
    items,
    deliveryDate,
    deliveryHourRange: deliveryHour,
  }: Partial<ISaveOrder>) => {
    dispatch(
      createCart({
        paymentMethod: "",
        price,
        items: items!,
        deliveryDate,
        deliveryHourRange: deliveryHour,
        deliveryCheckoutInfo,
        deliveryInfo,
        cleverlynkId: cleverlynk?.id!,
        companyId: company?.id!,
        currency,
        takeout: deliveryType !== PFXOrderFulfillmentType.DELIVERY,
        extras: order?.extras,
        identification,
        mandatoryCriteria,
        optionalCriteria,
        fulfillmentType: cleverlynk?.inPerson ? PFXOrderFulfillmentType.IN_PERSON : deliveryType,
      })
    );
  };

  const handleCreateAbandonedCartOrder = async ({
    paymentMethod,
    price,
    items,
    deliveryDate,
    deliveryHourRange: deliveryHour,
  }: Partial<ISaveOrder>) => {
    dispatch(
      createAbandonedCartOrder({
        paymentMethod: paymentMethod!,
        price,
        items: items!,
        deliveryDate,
        deliveryHourRange: deliveryHour,
        deliveryCheckoutInfo,
        deliveryInfo,
        cleverlynkId: cleverlynk?.id!,
        companyId: company?.id!,
        takeout: deliveryType !== PFXOrderFulfillmentType.DELIVERY,
        currency,
        extras: order?.extras,
        identification,
        mandatoryCriteria,
        optionalCriteria,
      })
    );
  };

  return {
    addItemToCart,
    addBatchItemsToCart,
    currencyAwareRound,
    generateDescription,
    handleSetOpenedUpselling,
    getPaymentMethods,
    gotToUserForm,
    // handleAttemptCleverlynkPin,
    handleChangeLocationInPrimaryTemplate,
    handleChangeLocationInDrawerTemplate,
    handleCreateAbandonedCartOrder,
    handleCreateOrder,
    handleRedirectToClynk,
    handleCreateSendOrder,
    handleSetNoLandings,
    handleGetAsyncDeliveryPrice,
    handleDeleteItemInCart,
    handleGetOrder,
    handleGoToPreviousStage,
    handleLoadOrderSummary,
    handleSetIsMobile,
    handleModifyCartIsOpen,
    handleModifyDeliveryCheckoutInfo,
    handleModifyItemsInCart,
    handleUpdatePrice,
    handleModifyLoadedLocalStorage,
    handleModifyOpenGeo,
    handleModifyOrderState,
    handleModifySnackbar,
    handleSetTransferOrderPhoto,
    handleModifySingleItemInCart,
    handleModifyOrderSummary,
    handleGetActiveGroupMembers,
    handleModifyTriggerNavToUserForm,
    handleModifyUnavailable,
    handleModifyCurrentClynkWithinGroup,
    handleSetConfirmLocationLater,
    handleSetCleverlynkStage,
    handleSwapItemsBetweenCleverlynks,
    handleUpdateItemInCart,
    handleWipeState,
    renderOptionsAsString,
  };
}
