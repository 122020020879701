import React, { useEffect, useRef, useMemo } from "react";
import { PFXCleverlynk, PFXCleverlynkGroupType, PFXCredentialsResponse } from "../models/Interfaces";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import {
  IUnavailableDialog,
  selectCleverlynk,
  selectCleverlynkGroup,
  selectCleverlynkId,
  selectCleverlynkStage,
  selectCompany,
  selectConfirmLocationLater,
  selectError,
  selectItemsInCart,
  selectLoadInitialData,
  selectLoadInitialDataFromId,
  selectCurrentClynkWithinGroup,
  isDefaultClynkPath,
} from "../redux/slices/cleverlynkSlice";
import { useSelector } from "react-redux";
import useCleverlynkFunctions from "../hooks/useCleverlynkFunctions";
import { PFXTemplateType, selectInitCatalog, selectTemplateType } from "../redux/slices/templateSlice";
import { useMediaQuery, useTheme } from "@material-ui/core";
import useCheckoutFunctions from "../hooks/useCheckoutFunctions";
import useMapService from "../hooks/MapServiceHooks/useMapService";
import oops from "../images/oops.png";

import { useTranslation } from "react-i18next";
import "../i18n/config";

const WEEKDAYS = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];

export interface IDiscount {
  type: "flat" | "percentage" | "none";
  amount: number;
}

export interface IDiscountInfo {
  discount: IDiscount;
  id: string;
  scope: string;
}

export interface IPriceAndDiscount {
  price: number;
  discount: IDiscount;
}

interface ICleverlynkContext {
  cleverlynk: PFXCleverlynk | undefined;
  credentials: PFXCredentialsResponse;
  basePath: string;
  baseDomain: string;
  loading: boolean;
}

export const CleverlynkContext = React.createContext<any | undefined>(undefined);

export default function CleverlynkContextProvider({ children }) {
  const checkGroupFirstTime = useRef<boolean>(true);
  const loadedSendOrder = React.useRef<boolean>(false);

  const error = useSelector(selectError);

  const itemsInCart = useSelector(selectItemsInCart);
  const cleverlynkGroup = useSelector(selectCleverlynkGroup);
  const chosenClynkWithinGroup = useSelector(selectCurrentClynkWithinGroup);
  const cleverlynk = useSelector(selectCleverlynk);
  const company = useSelector(selectCompany);
  const cleverlynkStage = useSelector(selectCleverlynkStage);
  const cleverlynkId = useSelector(selectCleverlynkId);
  const initCatalogSupervisor = useSelector(selectInitCatalog);
  const templateType = useSelector(selectTemplateType);

  const loadInitialDataFromIdSupervisor = useSelector(selectLoadInitialDataFromId);
  const loadInitialDataSupervisor = useSelector(selectLoadInitialData);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const initialDrawerCatalogMovementRef = useRef<boolean>(false);

  const location = useLocation();
  const history = useHistory();
  // setters
  const { handleModifyDeliveryInfo } = useMapService();

  const { handleAddDiscount, calculatePrice, handleModifyPrice } = useCheckoutFunctions();

  const { t } = useTranslation();

  const {
    handleModifySnackbar: setSnackbar,
    handleModifyOpenGeo,
    handleGetOrder,
    handleChangeLocationInPrimaryTemplate,
    handleChangeLocationInDrawerTemplate,
    handleModifyUnavailable,
    handleSetConfirmLocationLater: setConfirmLocationLater,
    handleUpdatePrice,
    handleSetIsMobile,
    handleModifyCurrentClynkWithinGroup,
  } = useCleverlynkFunctions();

  const loading = React.useMemo(() => {
    return loadInitialDataFromIdSupervisor.status === "loading" || loadInitialDataSupervisor.status === "loading";
  }, [loadInitialDataFromIdSupervisor, loadInitialDataSupervisor]);

  useEffect(() => {
    handleSetIsMobile(isMobile);
  }, [isMobile]);

  useEffect(() => {
    if (initCatalogSupervisor.status === "succeeded" && cleverlynkStage === 1) {
      if (templateType !== PFXTemplateType.DRAWER) {
        handleChangeLocationInPrimaryTemplate({ location });
      } else if (!initialDrawerCatalogMovementRef.current) {
        handleChangeLocationInDrawerTemplate(location);
        initialDrawerCatalogMovementRef.current = true;
      }
    }
  }, [history.location, initCatalogSupervisor]);

  useEffect(() => {
    if (cleverlynkStage !== 3) {
      handleModifyPrice(calculatePrice(false, false, false));
    }
  }, [itemsInCart]);

  const baseDomain = useMemo(() => {
    if (company?.domain) {
      return `https://${company?.domain}`;
    }
    if (company?.subdomain) {
      return process.env.REACT_APP_PUBLIC_URL!.replace("*", company?.subdomain);
    }
    return process.env.REACT_APP_PUBLIC_URL!.replace("*.", "");
  }, [cleverlynk, company]);

  const basePath = useMemo(() => {
    const regex1 = /\/(unavailable|closed|checkout|pin|menu).*/;
    const regex2 = /\/undefined/;
    const url = location.pathname.replace(regex1, "").replace(regex2, "");
    const fallbackId = url.split("/")[1];

    if (isDefaultClynkPath(location.pathname) && !chosenClynkWithinGroup) return "/";
    if (!cleverlynkGroup || (!cleverlynkGroup?.mandatory && !chosenClynkWithinGroup))
      return `/${chosenClynkWithinGroup?.id ?? cleverlynk?.id ?? fallbackId ?? ""}`;
    if (url.split("/").length === 3)
      return `/${chosenClynkWithinGroup?.id ?? cleverlynk?.id ?? fallbackId ?? ""}/${
        chosenClynkWithinGroup || url.includes("redirect") ? "redirect" : ""
      }`;
    return `/${chosenClynkWithinGroup?.id ?? cleverlynk?.id ?? fallbackId ?? ""}${
      chosenClynkWithinGroup || url.includes("redirect") ? "/redirect" : ""
    }`;
  }, [cleverlynk, chosenClynkWithinGroup, location.pathname]);

  useEffect(() => {
    if (basePath.includes("/redirect") && !chosenClynkWithinGroup) {
      handleModifyCurrentClynkWithinGroup({ id: cleverlynk?.id! });
    }
  }, [basePath]);

  const send = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return { send: queryParams.get("send"), start: queryParams.has("start") };
  }, []);

  useEffect(() => {
    if (cleverlynkGroup?.mandatory && !chosenClynkWithinGroup) {
      // handleModifyOpenGeo(true);
    }
  }, [cleverlynkGroup]);

  useEffect(() => {
    const getGeoSnackbarMessage = () => {
      if (cleverlynkGroup) {
        if (cleverlynkGroup?.mandatory && !chosenClynkWithinGroup && !isDefaultClynkPath(location.pathname)) {
          checkGroupFirstTime.current = false;
        } else if (!cleverlynkGroup?.mandatory && checkGroupFirstTime.current) {
          checkGroupFirstTime.current = false;
          setConfirmLocationLater(true);
          setSnackbar({
            type: "info",
            message: t("verifyAreaLater"),
            time: 10000,
          });
        }
      }
    };
    if (!loading) {
      const pathnameArray = location.pathname.split("/");
      const redirect = pathnameArray[2] === "redirect";
      if (!redirect && (send.send === null || send.start)) getGeoSnackbarMessage();
      if (chosenClynkWithinGroup && checkGroupFirstTime.current) {
        checkGroupFirstTime.current = false;
        if (cleverlynkGroup?.type === PFXCleverlynkGroupType.GEO) {
          setSnackbar({
            type: "success",
            message: t("correctArea"),
            time: 2000,
          });
        }
      }
      if (redirect && !chosenClynkWithinGroup && cleverlynk?.id) {
        // history.replace({ pathname: `/${cleverlynk?.id}` });
      }
    }
  }, [loading]);

  useEffect(() => {
    if (cleverlynkId) {
      const queryParams = new URLSearchParams(location.search);
      const send = queryParams.get("send");
      const isStart = queryParams.has("start");
      if (!loadedSendOrder.current && send && cleverlynkId && cleverlynkId !== "") {
        handleGetOrder({ isStart, orderId: send, addDiscount: handleAddDiscount });
        loadedSendOrder.current = true;
      }
    }
  }, [cleverlynkId]);

  useEffect(() => {
    if (cleverlynkStage === 2 && !location.pathname.includes("info"))
      history.replace({
        pathname: `${basePath}/info`.replace("//", "/"),
      });
  }, [cleverlynkStage]);

  useEffect(() => {
    handleUpdatePrice(itemsInCart);
  }, [itemsInCart]);

  useEffect(() => {
    const unavailableDataTitle = () => {
      return "Esta tienda esta cerrada, te invitamos a hacer tu pedido a traves de nuestras redes sociales";
    };
    const unavailableDataURL = () => {
      return undefined;
    };

    const unavailableData: IUnavailableDialog = {
      open: true,
      image: oops,
      title: unavailableDataTitle(),
      url: unavailableDataURL(),
      backgroundColor: "#FFFFFF",
      textColor: "#1d2c41",
      closed: false,
    };
    if (error && error?.data?.salt) {
      history.push({
        pathname: `${basePath}/pin`,
      });
    } else if (error) {
      if (error?.data?.logoUrl) unavailableData.image = error?.data?.logoUrl;
      if (error?.data?.contactMessage) unavailableData.subtitle = error?.data?.contactMessage;

      if (error?.data?.hours) {
        const times = error?.data?.hours.map((h, i) =>
          h
            ? WEEKDAYS[i] +
              ": " +
              moment(h[0], "HH:mm").format("hh:mm A") +
              " - " +
              moment(h[1], "HH:mm").format("hh:mm A")
            : "#####"
        );

        unavailableData.closed = true;
        unavailableData.title = "En este momento no tenemos servicio";
        unavailableData.subtitle = `Nuestros horarios de atención son: \n ${times
          .filter(t => t !== "#####")
          .join("\n")}\n\n Horas de ${error?.data?.timezone ?? "America/Bogota"}`;
        handleModifyUnavailable(unavailableData);
        history.push({
          pathname: `${basePath}/closed`.replace("//", "/"),
        });
      } else {
        handleModifyUnavailable(unavailableData);
        history.push({
          pathname: `${basePath}/unavailable`.replace("//", "/"),
        });
      }
    } else handleModifyUnavailable(undefined);
  }, [error]);

  // cleverlynkSlice
  useEffect(() => {
    if (chosenClynkWithinGroup?.id) {
      handleModifyOpenGeo(false);
    }
  }, [chosenClynkWithinGroup]);

  return (
    <CleverlynkContext.Provider
      value={{
        loading,
        basePath,
        baseDomain,
      }}>
      {children}
    </CleverlynkContext.Provider>
  );
}

export const useCleverlynkContext = () => React.useContext<ICleverlynkContext>(CleverlynkContext);
